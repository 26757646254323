@import "../variables";

.firstStep {
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media (max-width: 1180px) {
    flex-direction: column;
    align-items: center;
  }
}
.firstStepItem {
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    gap: 20px;
  }
}
.firstStepGrid {
  @media (max-width: 660px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
  }
  @media (max-width: 540px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
  }
}
.firstStepItemHeader {
  padding: 10px;
  display: grid;
  grid-template-columns: 4fr repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  align-items: center;
  text-transform: uppercase;
  p {
    color: #686877;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 178.187%; /* 24.946px */
    text-align: center;
    &:first-child {
      text-align: start;
    }
    @media (max-width: 1024px) {
      font-size: 12px;
    }
    @media (max-width: 768px) {
      font-size: 10px;
    }
  }
  @media (max-width: 1024px) {
    padding: 5px 10px;
  }
  @media (max-width: 768px) {
    grid-column-gap: 5px;
  }
  @media (max-width: 660px) {
    display: none;
  }
}
.order {
  width: 350px;
  height: fit-content;
  padding: 30px;
  border-radius: 12px;
  border: 1px solid $gray;
  background: $white;
  box-shadow: 0px 4px 12px 0px rgba(207, 207, 207, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    width: 330px;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
}
.orderTitle {
  margin-bottom: 15px;
  width: 100%;
  text-align: start;
  color: $black;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;
  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
.orderHeader {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  p {
  
    text-transform: uppercase;
    color: #70737c;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 178.187%; /* 24.946px */
  }
  span{
    margin: 0px 5px;
  }
  @media (max-width: 768px) {
    p {
      font-size: 13px;
    }
    margin-bottom: 15px;
  }
}
.orderPrice {
  color: $black !important;
  font-family: "Noto Sans", sans-serif;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 25px; /* 138.889% */
  letter-spacing: -0.72px;
  span {
    font-size: 14px !important;
    text-transform: lowercase !important;
    margin-left: 5px;
  }
  @media (max-width: 768px) {
    span {
      font-size: 13px;
    }
    font-size: 13px;
  }
  @media (max-width: 440px) {
    font-size: 16px !important;
  }
}
.promocode {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  p {
    text-transform: uppercase;
    color: #70737c;
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 178.187%; /* 24.946px */
  }
  input {
    width: 100%;
    padding: 14px 24px;
    border-radius: 4px;
    border: 1px solid $gray;
    color: $black;
    @media (max-width: 768px) {
      padding: 12px 20px;
    }
    &::placeholder {
      color: var(--text_secondary, #70737c);
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 175% */
      @media (max-width: 768px) {
        font-size: 13px;
      }
    }
    &:hover {
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
    }
  }
}
.promocodeDiscount {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 15px;
  color: $red;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 139.023%; /* 22.244px */
  span {
    margin: 0px 2px;
    font-weight: 700;
    font-size: 14px;
  }
}
.orderFooter {
  margin-bottom: 5px;
  border-top: 1px solid #efefef;
  padding: 25px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  p {
    text-transform: uppercase;
    color: #70737c;
    font-family: "Noto Sans", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 178.187%; /* 24.946px */
  }
  @media (max-width: 370px) {
    p {
      font-size: 12px;
    }
  }
}
.orderBtn {
  cursor: pointer;
  width: 100%;
  padding: 11px 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #8fc23d;
  p {
    color: $white;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 178.187%; /* 28.51px */
    letter-spacing: -0.56px;
  }
  &:hover {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
  @media (max-width: 768px) {
    padding: 8px 25px;
  }
}
