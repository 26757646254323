.adminHeader {
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 50px;
    gap: 20px;
    a {
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      line-height: 1.33;
      font-weight: 700;
      color: rgb(0, 0, 0);
      padding: 5px 24px;
      border-radius: 10px;
      background-color: #e9eb46;
    }
    button {
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      line-height: 1.33;
      font-weight: 700;
      color: #fff;
      background-color: transparent;
      padding: 5px 24px;
      border-radius: 10px;
      background-color: #f53c3c;
    }
  }
}
.lang {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  p {
    cursor: pointer;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    line-height: 1.33;
    font-weight: 700;
    color: rgb(0, 0, 0);
  }
}
