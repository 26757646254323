@import "../variables";

.basketCard {
  padding: 20px 10px;
  border-bottom: 1px solid $gray;
  position: relative;
  @media (max-width: 1024px) {
    padding: 10px 7px;
  }
  @media (max-width: 768px) {
    padding: 5px 5px;
  }
  @media (max-width: 660px) {
    border-radius: 12px;
    border: 1px solid #efefef;
    margin: 5px 0;
    padding: 10px 20px;
  }
  @media (max-width: 540px) {
    padding: 30px 50px;
  }
  @media (max-width: 470px) {
    padding: 20px 30px;
  }
  @media (max-width: 350px) {
    padding: 10px 20px;
  }
}
.item {
  display: grid;
  grid-template-columns: 4fr repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  align-items: center;
  @media (max-width: 768px) {
    grid-column-gap: 5px;
  }
  @media (max-width: 660px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  @media (max-width: 470px) {
    gap: 5px;
  }
}
.header {
  display: grid;
  grid-template-columns: 1fr 7fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 15px;
  align-items: center;
  @media (max-width: 660px) {
    flex-direction: column-reverse;
  }
}
.title {
  p {
    overflow: hidden;
    color: $black;
    font-family: "Noto Sans", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 178.187%; /* 24.946px */
    @media (max-width: 1024px) {
      font-size: 14px;
      height: 50px;
    }
    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 18px; /* 24.946px */
      height: 35px;
    }
  }
}
.count {
  width: 140px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid $gray;
  padding: 5px;
  @media (max-width: 768px) {
    height: 30px;
    width: 110px;
    gap: 3px;
  }
  &__btn {
    cursor: pointer;
    background-color: $gray;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      padding: 5px;
      border-radius: 5px;
    }
  }
  svg {
    width: 15px;
    height: 15px;
    @media (max-width: 768px) {
      width: 10px;
      height: 10px;
    }
  }
  &__number {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      height: 100%;
      width: 50px;
      padding: 5px;
      color: inherit;
      border-radius: 10px;
      text-align: center;
      background: var(--Invisible-green, rgba(53, 151, 64, 0.08));
      color: $black;
      font-family: "Noto Sans", sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 139.023%; /* 20.853px */
      @media (max-width: 768px) {
        border-radius: 5px;
        font-size: 12px;
      }
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
}
.img {
  overflow: hidden;
  width: 64px !important;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border-radius: 20px;
  border: 1px solid $gray;
  @media (max-width: 768px) {
    border-radius: 10px;
    width: 50px !important;
    height: 50px;
  }
  img {
    height: 100%;
  }
}
.price,
.totalPrice {
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: $black;
    text-align: center;
    font-family: "Noto Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 138.889% */
    letter-spacing: -0.72px;
  }
  span {
    margin-left: 5px;
    color: $black;
    text-align: center;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 138.889% */
    letter-spacing: -0.72px;
  }
  @media (max-width: 768px) {
    p {
      font-size: 15px;
      line-height: 16px; /* 138.889% */
    }
    span {
      font-size: 12px;
      line-height: 16px; /* 138.889% */
    }
  }
}
.price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 660px) {
    flex-direction: row-reverse;
    gap: 3px;
    align-items: center;
  }
  @media (max-width: 540px) {
    gap: 10px;
  }
}
.crossed {
  text-decoration: line-through;
  color: $ashen !important;
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 15px !important;
  letter-spacing: -0.72px;
  span {
    color: $ashen !important;
  }
}
.delete {
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 5px;
  border-radius: 50%;
  background-color: $red;
  top: 10px;
  left: 0;
  @media (max-width: 768px) {
    padding: 2px;
  }
  svg {
    width: 15px;
    height: 15px;
    @media (max-width: 768px) {
      width: 12px;
      height: 12px;
    }
  }
  @media (max-width: 660px) {
    right: 10px;
    left: auto;
  }
}

.nameSpan {
  display: none;
  text-transform: uppercase;
  color: #686877;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 178.187%; /* 24.946px */
  text-align: center;
  @media (max-width: 660px) {
    display: flex;
  }
  @media (max-width: 470px) {
    font-size: 8px !important;
  }
}
.block {
  width: 100%;
  @media (max-width: 660px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
