@import "../variables";

.paymentDeliveryContant {
  padding: 30px 0px 50px 0px;
  @media (max-width: 1024px) {
    padding: 20px 0px 50px 0px;
  }
  @media (max-width: 900px) {
    padding: 15px 0px 50px 0px;
  }
  @media (max-width: 650px) {
    padding: 10px 0px 50px 0px;
  }
}
.title {
  color: $black;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 139.023%; /* 38.926px */
  margin-bottom: 20px;
  @media (max-width: 900px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
  @media (max-width: 600px) {
    font-size: 16px;
  }
  @media (max-width: 400px) {
    font-size: 14px;
  }
}
.contant {
  display: flex;
  flex-direction: column;
  gap: 30px;
  &__item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (max-width: 600px) {
      gap: 5px;
    }
  }
  @media (max-width: 600px) {
    gap: 20px;
  }
  @media (max-width: 450px) {
    gap: 15px;
  }
}
.subContant {
  padding-left: 2%;
  display: flex;
  flex-direction: column;
  gap: 3px;
  &__title {
    color: $black !important;
    font-family: "Rubik", sans-serif !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 139.023%; /* 44.487px */

    @media (max-width: 600px) {
      font-size: 14px !important;
    }
  }

  @media (max-width: 600px) {
    p {
      font-size: 12px !important;
    }
  }
}
.subtitle {
  color: $black;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 139.023%; /* 44.487px */
  @media (max-width: 900px) {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
  }
}
.descriptions {
  display: flex;
  flex-direction: column;
  gap: 5px;
  nav {
    ul {
      display: flex;
      flex-direction: column;
      gap: 5px;
      ol {
        text-align: justify;
        padding-left: 1.5em;
        color: #686877;
        font-family: "Noto Sans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 155.023%; /* 31.005px */
        @media (max-width: 600px) {
          font-size: 12px !important;
        }

        &::before {
          content: "•"; /* Устанавливает содержимое псевдоэлемента как точку */
          display: inline-block; /* Делает точку блочным элементом */
          width: 1em; /* Задает ширину точки (можно настроить по вашему вкусу) */
          margin-left: -1.5em; /* Компенсирует отступ слева для точек */
          margin-right: 0.5em; /* Добавляет пространство между точкой и текстом */
        }
      }
    }
  }
  p {
    text-align: justify;
    color: #686877;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 155.023%; /* 31.005px */
    @media (max-width: 600px) {
      font-size: 12px !important;
    }
  }
}
