@import "../variables";

:root {
  --background: #f8f8ff;
  --table-background: #fcfcfd;
  --primary: #272727;
  --gray: #a0a7b1;
  --odd-row: #f6f9ff;
  --th-background: #f4f5fc;
  --gray-background: #edeef1;
  --gray-mid: #f1f2f6;
}
.orderItems {
  position: relative;
  width: 100%;
  font-family: "Noto Sans", sans-serif;
  margin: 0 auto;
  display: flex;
  justify-content: center !important;
  height: 100vh;
  background-color: var(--background);
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &__container {
    @media (min-width: 1600px) {
      margin: 0 auto;
      max-width: 90vw;
    }
    @media (max-width: 1599px) {
      width: 100%;
      margin: 0 auto;
      padding: 0px 60px;
    }
    @media (max-width: 1300px) {
      margin: 0 auto;
      padding: 0px 0px;
    }
  }
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden !important;
    gap: 20px;
    border-radius: 16px;
    background-color: var(--table-background);
    padding: 24px;
    border: 2px solid var(--gray-background);
    box-shadow: 0px 4px 16px 0px rgba(148, 156, 169, 0.15);
    margin: 0 auto;
    @media (max-width: 1024px) {
      padding: 0px;
    }
  }
}
.caption-container {
  font-size: 1.12rem;
  font-weight: 700;
  text-align: left;
  padding-bottom: 16px;
  border-bottom: 2px solid var(--gray-background);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.table-widget {
  overflow: hidden;
  border-radius: 16px;
  background-color: var(--table-background);
  padding: 24px;
  width: 100%;
  margin: 16px;
  max-width: 90%;
  border: 2px solid var(--gray-background);
  box-shadow: 0px 4px 16px 0px rgba(148, 156, 169, 0.15);
  text-align: left;
  @media (max-width: 1224px) {
    max-width: 98%;
  }
}

.table-wrapper {
  overflow-x: auto;
  overflow-y: auto;
  padding-bottom: 8px;
  max-height: 92vh;
}
.tableHeader th {
  position: sticky !important;
  top: 0;
  background-color: #f4f5fc; /* или другой желаемый цвет фона */
  z-index: 10 !important; /* чтобы заголовок оставался поверх других элементов */
}
.table-widget table {
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.table-widget thead {
  position: sticky;
}
.tableHeader th:first-child {
  z-index: 11 !important; /* чтобы заголовок оставался поверх других элементов */
}
.table-widget th {
  cursor: pointer;
  padding: 8px 8px 8px 8px;
  font-size: 0.875rem;
  color: #000;
  width: auto !important;
  /*  white-space: nowrap; */
  background-color: var(--th-background);
  text-align: center;
  span {
    margin: 0 1px;
  }
}
.table-widget tfoot {
  margin-top: 16px;
  border-top: 2px solid var(--gray-background);
}
.table-widget td {
  padding: 8px;
  vertical-align: middle;
  border-bottom: 2px solid var(--gray-background);
  font-size: 14px !important;
  text-align: left;
}
.caption-container {
  display: flex;
  width: 100%;
  align-items: center;
}
.table-row-count {
  font-size: 0.8rem;
  font-weight: 700;
  background-color: var(--gray-mid);
  padding: 8px;
  display: inline-flex;
  align-items: center;
  margin-left: 16px;
  border-radius: 8px;
}
.stock-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
.stock-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.stock-info__ticker {
  font-weight: 700;
}
.stock__name {
  font-weight: 700;
  white-space: nowrap;
}
.stock img {
  width: 42px;
  height: 42px;
  object-fit: contain;
  border-radius: 0.5rem;
}
.table-widget {
  min-height: 80px;
}
.addProduct {
  background-color: transparent;
  margin-top: 10px;
  z-index: 1;
  position: sticky;
  left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  margin: 5px;
  svg {
    height: 20px;
    width: 20px;
  }
}
.sticky-right {
  position: sticky;
  z-index: 1;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  background-color: var(--table-background);
  padding-bottom: 2px;
}
.caption-container {
  display: flex;
  align-items: center;
}
.caption-container svg {
  margin-top: -2px;
}
.table-title {
  display: flex;
  gap: 12px;
}
.price {
  font-weight: 600;
}
.up {
  color: #2ecc71;
}
.down {
  color: #e3452f;
}
.sticky-right button {
  border-radius: 12px;
  background-color: var(--gray-mid);
  color: var(--primary);
  outline: none;
  font-weight: 700;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 10px 16px;
}
.sticky-right button:nth-child(1):hover {
  background-color: red;
}
.sticky-right button:nth-child(1):hover path {
  fill: #fff;
}
/* Styles for the second button inside .sticky-right */
.sticky-right button:nth-child(2):hover {
  background-color: rgb(73, 69, 255);
}

.sticky-right button:nth-child(2):hover path {
  fill: #fff;
}
.table-wrapper::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
.table-wrapper::-webkit-scrollbar-track {
  background: var(--gray-mid);
  border-radius: 6px;
}
.table-wrapper::-webkit-scrollbar-thumb {
  background: var(--gray);
  border-radius: 5px;
}
.pagination {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  z-index: 128;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalClose {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.saveData {
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 6px;
  color: #fff;
  background: rgb(73, 69, 255);
}
.modalContent {
  position: fixed;
  border-radius: 16px;
  background-color: var(--table-background);
  padding: 24px;
  width: 700px;
  margin: 16px;
  max-width: 90%;
  border: 2px solid var(--gray-background);
  box-shadow: 0px 4px 16px 0px rgba(148, 156, 169, 0.15);
  overflow: hidden;
  z-index: 130;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  &__items {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    div {
      width: 100%;
      input,
      textarea {
        width: 100%;
        border-radius: 10px;
        padding: 10px;
        background-color: var(--table-background);
        border: 2px solid var(--gray-background);
        box-shadow: 0px 4px 16px 0px rgba(148, 156, 169, 0.15);
      }
      p {
        margin-left: 7px;
        margin-bottom: 3px;
        font-weight: 600;
        font-size: 14px;
        color: #000;
      }
    }
  }
  input[readonly] {
    background-color: #f0f0f0; /* Задайте нужный серый цвет */
    color: #333; /* Цвет текста, чтобы он был виден на фоне */
  }
}
.note {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 90%;
  overflow: hidden;
  div {
    width: 100%;

    border-radius: 16px;
    background-color: var(--table-background);
    border: 2px solid var(--gray-background);
    box-shadow: 0px 4px 16px 0px rgba(148, 156, 169, 0.15);
    textarea {
      padding: 24px;
      width: 100%;
      height: 200px;
      resize: none;
    }
    textarea::-webkit-scrollbar {
      width: 7px;
      margin-top: 20px;
    }
    /* Цвет полосы прокрутки */
    textarea::-webkit-scrollbar-thumb {
      background-color: #bcbcbc;
      border-radius: 5px;
    }
    /* Цвет фона полосы прокрутки */
    textarea::-webkit-scrollbar-track {
      background-color: none;
    }
  }
}
.noteTitle {
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
  padding: 10px;
  color: #000;
  text-transform: uppercase;
}
.information {
  width: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  p {
    white-space: nowrap;
    margin-left: 7px;
    margin-bottom: 3px;
    font-weight: 600;
    font-size: 14px;
    color: #000;
  }
  input,
  textarea {
    border-radius: 10px;
    padding: 10px;
    background-color: var(--table-background);
    border: 2px solid var(--gray-background);
    box-shadow: 0px 4px 16px 0px rgba(148, 156, 169, 0.15);
  }
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap !important;
    input {
      width: 100%;
    }
    div:first-child {
      max-width: 800px !important;
      min-width: 450px !important;
    }

    div:not(:first-child) {
      min-width: 200px !important;
    }
  }
  &__main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }

  &__mainInf {
    flex: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 10px;

    &__items {
      flex-wrap: wrap !important;
      display: flex;
      flex-direction: row;
      gap: 10px;
      width: 100% !important;
      div {
        min-width: 180px !important;
        input {
          width: 100%;
        }
      }
      div:nth-last-child(3) {
        flex: 1 1 0;
        min-width: 420px !important;
      }
    }
  }
  &__footer {
    display: flex;
    flex-wrap: wrap !important;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    div {
      min-width: 300px !important;
      input {
        width: 100%;
      }
    }
  }
}
.saveData {
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 6px;
  color: #fff;
  background: rgb(73, 69, 255);
}
.disabled {
  background: rgb(114, 114, 116);
}
.cancelData {
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 6px;
  color: #fff;
  background: rgb(197, 195, 195);
}
.deleteData {
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 6px;
  color: #fff;
  background: rgb(239, 32, 32);
}
.header {
  padding: 30px 0 15px 0;
  width: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
  div {
    white-space: nowrap;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
  }
  p {
    white-space: nowrap;

    font-size: 13px;
    font-weight: 600;
    text-align: left;
    color: #000;
  }
  span {
    font-size: 14px;
    font-weight: 600 !important;
    text-align: left;
    color: #000;
  }
}
.tools {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  flex-wrap: wrap;
}
.stateChange {
  select {
    font-family: "Noto Sans", sans-serif;
    font-weight: 700 !important;
    font-size: 15px;
    text-transform: uppercase;
    padding: 8px 16px;
    border-radius: 6px;
    color: #ff0000;
    text-align: center;

    -webkit-appearance: none; /* для Safari */
    -moz-appearance: none; /* для Firefox */
    appearance: none; /* стандарт для остальных браузеров */
    /* добавьте свой фоновый цвет или изображение, если требуется */
    background-color: white;
  }
}
.tableHeader {
  z-index: 114;
}
.tableHeader th {
  position: sticky !important;
  top: 0;
  background-color: #f4f5fc; /* или другой желаемый цвет фона */
  z-index: 10 !important; /* чтобы заголовок оставался поверх других элементов */
}

.table-widget tr td:nth-child(1),
.table-widget tr td:nth-child(3),
.table-widget tr td:nth-child(4),
.table-widget tr td:nth-child(6) {
  text-align: center !important;
}
.table-widget tr :right:last-child {
  height: 50px !important;
}
.modalTools {
  margin-top: 30px;
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  align-items: center;
}
.texNotFound {
  text-transform: lowercase;
  color: #e3452f !important;
  font-size: small;
}
.confirmOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2px);
  z-index: 128;
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirmContent {
  position: fixed;
  border-radius: 16px;
  background-color: var(--table-background);
  padding: 24px;
  width: 500px;
  margin: 16px;
  max-width: 90%;
  border: 1px solid #000;
  box-shadow: 0px 4px 16px 0px rgba(148, 156, 169, 0.15);
  overflow: hidden;
  z-index: 130;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  p {
    text-align: center;
  }
}
.confirmBtn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.confirmTitle {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding: 10px;
  color: #000;
}
.confirmDescriptions {
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
  padding: 10px;
  color: #000;
}
