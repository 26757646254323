@import "../../variables";

.scroll-to-top-widget {
  -webkit-box-shadow: 0.5px 0 18px 5px #3cc23e;
  -moz-box-shadow: 0.5px 0 18px 5px #3cc23e;
  box-shadow: 0.5px 0 18px 5px #3cc23e;

  background-color: $green;
  border: 1px solid $green;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  lord-icon {
    transform: rotate(-90deg);
    transform-origin: center; /* Центр иконки для поворота */
    width: 100%;
    height: 100%;
  }
  &:hover {
    -webkit-box-shadow: 0.5px 0 18px 5px #3cc23e;
    -moz-box-shadow: 0.5px 0 18px 5px #3cc23e;
    box-shadow: 0.5px 0 18px 5px #3cc23e;
    background-color: #6b932c;
  }
  &.visible {
    display: flex;
  }

  @media (min-width: 1025px) {
    width: 40px;
    height: 40px;
    lord-icon {
      width: 30px;
      height: 30px;
    }
  }
  @media (max-width: 1024px) {
    width: 40px;
    height: 40px;
    lord-icon {
      width: 25px;
      height: 25px;
    }
  }

}
