@import "../variables";

.blogPostCarousel {
  position: relative;
  padding: 30px 0px 30px 0px;
  background-color: #eaeaea;
  overflow: hidden;
  @media (max-width: 767px) {
    padding: 20px 0px 20px 0px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 35px;
    @media (max-width: 767px) {
      gap: 10px;
    }
  }
}
.title {
  color: #000;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: clamp(20px, calc(1.25rem + ((1vw - 3.2px) * 2.3438)), 40px);
  min-height: 0vw;
  font-style: normal;
  font-weight: 600;
  line-height: 139.023%; /* 69.511px */
/*   margin-bottom: 15px; */
}
.carousel {
  position: relative;
  overflow: hidden;
  width: 100% !important;

  :global {
    .slick-list {
      display: flex;
      margin-left: -6px !important;
      margin-right: -6px !important;
      
    }
    .slick-track {
      display: flex;
      flex: 1 1 0 !important;
     
      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
    }
    .slick-slide {
      margin: 0px 18px;
      /*   @media (min-width: 1024px) {
        min-width: 40% !important;
      } */
    }
    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }
}
.item {
  width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: center !important;
  margin: 0 auto !important;
}
.footer {
  display: flex;
  justify-content: center;
}
.blogLink {
  width: fit-content;
  padding: 10px 30px;
  border-radius: 53px;
  border: 1px solid #8fc23d;
  margin: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 767px) {
    padding: 7px 30px;

  }
  p {
    color: #8fc23d;
    font-family: "Noto Sans";
    font-size: clamp(13px, calc(0.9375rem + ((1vw - 3.2px) * 0.7813)), 18px);
    min-height: 0vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &:hover {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
  &:active {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
}
