@import "../variables";

.productItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.items {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  @media (max-width: 1385px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1180px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 580px) {
    grid-template-columns: repeat(2, 1fr);
     
  }
  
}
.productItemsEmpty {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  p {
    color: #686877;
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 139.023%; /* 25.024px */
  }
}
.pagination {
  margin-top: 20px;
  div {
    nav {
      ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3px;
        li {
          width: 30px;
          height: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        @media (max-width: 350px) {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 3px;
          li {
            overflow: hidden;
            width: 25px;
            height: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
.paginationHidden {
  display: none;
}
