@import "../variables";

.blogPostCard {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 100%;
  width: 100%;
  max-height: 505px;
  max-width: 675px;
  display: flex;
  justify-content: center;
  padding-bottom: 85px;
}
.img {
  position: relative;
  width: 100%;
  height: 400px !important;
  z-index: 0;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  transform: 0.5s ease-in-out !important;
  &:hover {
    transform: 0.5s ease-in-out !important;
  }
  @media (max-width: 1224px) {
    max-height: 278px;
  }

  @media (max-width: 767px) {
    max-height: 200px;
  }
  img {
    transform: 0.5s ease-in-out !important;
    object-fit: cover;
    height: 100% !important;
    width: 130%;
    transform: scale(1.1);
  }
}

.descriptions {
  max-height: 228px !important;
  overflow: hidden;
  width: calc(100% - 36px);
  z-index: 1;
  position: absolute;
  bottom: 32px;
  margin: 0 33px;
  display: flex;
  flex-direction: column;
  gap: 19px;
  padding: 18px 57px 21px 57px;
  border-radius: 31px 34px 30px 30px;
  background: #fff;
  box-shadow: 0px 20px 20px 0px rgba(167, 167, 167, 0.25);

  @media (max-width: 1224px) {
    margin: 0 18px;
    padding: 20px;
    gap: 12px;
  }
  @media (max-width: 767px) {
    margin: 0 18px;
    padding: 14px;
  }
}
.date {
  color: #303030;
  font-family: "Noto Sans";
  font-size: clamp(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.3906)), 18px);
  min-height: 0vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.data {
  display: flex;
  flex-direction: column;
  gap: 14px;
  @media (max-width: 1224px) {
    gap: 7px;
  }
  &__title {
    width: 100%;
    height: 27px;
    overflow: hidden;
    color: #303030;
    font-family: "Noto Sans";
    font-size: clamp(14px, calc(0.875rem + ((1vw - 3.2px) * 0.4688)), 20px);
    min-height: 0vw;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @media (max-width: 767px) {
      height: 20px;
    }
  }
  &__info {
    width: 100%;
    height: 62px;
    overflow: hidden;
    color: #303030;
    font-family: "Noto Sans";
    font-size: clamp(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.3125)), 17px);
    min-height: 0vw;
    font-style: normal;
    font-weight: 300;
    line-height: 155.023%; /* 26.354px */
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @media (max-width: 767px) {
      height: 40px;
    }
  }
}
.btn {
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  transition: transform 0.3s;
  p {
    color: #303030;
    font-family: "Roboto", sans-serif;
    font-size: clamp(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.5469)), 20px);
    min-height: 0vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  svg {
    height: 19px;
    width: 19px;
  }
}
