@import "../../variables";

.paymentDeliveryBreadcrumb {
  margin-top: 15px;
  @media (max-width: 768px) {
    margin-top: 0px;
  }
  @media (max-width: 320px) {
    margin-top: 7px;
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    a,
    span,
    p {
      color: #81818b;
      font-family: "Rubik", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      height: 15px;
      width: fit-content;
      white-space: nowrap;
    }
    p {
      max-width: 100%;
      overflow: hidden;
    }
  }
}
