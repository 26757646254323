@import "../variables";

.aboutUsDescriptions {
  padding: 30px 0 40px 0;
  box-shadow: 0px 2px 4px 0px rgba(51, 196, 223, 0.2);
  @media (max-width: 1224px) {
    padding: 20px 0 40px 0;
  }
  @media (max-width: 1024px) {
    padding: 15px 0 40px 0;
  }
  @media (max-width: 850px) {
    padding: 10px 0 40px 0;
  }
  @media (max-width: 650px) {
    padding: 10px 0 40px 0;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media (max-width: 420px) {
      gap: 20px;
    }
  }
}
.title {
  color: $black;
  font-family: "Rubik", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 139.023%; /* 44.487px */
  @media (max-width: 900px) {
    font-size: 24px;
  }
  @media (max-width: 850px) {
  }
  @media (max-width: 650px) {
    font-size: 20px;
    margin-bottom: 0px;
  }
}
.top {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12%;

  @media (max-width: 850px) {
    gap: 20px;
  }
  @media (max-width: 650px) {
    gap: 15px;
  }
  @media (max-width: 420px) {
    gap: 10px;
  }
  &__contant {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 50%;
    @media (max-width: 1224px) {
      align-items: center;
      width: 100%;
      gap: 20px;
    }
    @media (max-width: 850px) {
      gap: 20px;
    }
    @media (max-width: 650px) {
      gap: 10px;
    }
  }
  &__descriptions {
    display: flex;
    flex-direction: column;
    gap: 25px;
    p {
      color: $black;
      text-align: justify;
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 155.023%; /* 31.005px */
    }
    @media (max-width: 1224px) {
      gap: 15px;
      p {
        text-align: justify;
        font-size: 12px;
      }
    }
    @media (max-width: 420px) {
      gap: 5px;
      p {
        font-size: 12px;
      }
    }
  }
}
.imgTable {
  display: none;
  @media (max-width: 1224px) {
    display: flex;
    height: 250px;
    img{
      height: 100%;
      object-fit: cover;
    }
  }
}
.img {
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border: 1px solid $green;
  border-radius: 20px;
  overflow: hidden;
  img {
    height: 100%;
    width: auto;
    object-fit: cover;
  }
  /*  @media (max-width: 1024px) {

    border: none;
    height: 250px;
    aspect-ratio: 1/1;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  } */

  @media (max-width: 1224px) {
    display: none;
  }
  @media (max-width: 768px) {
    border-radius: 10px;
  }
  @media (max-width: 430px) {
    height: 120px;
    border-radius: 7px;
  }
  @media (max-width: 380px) {
    height: 100px;
  }
}
.bottom {
  &__descriptions {
    display: flex;
    flex-direction: column;
    gap: 25px;
    p {
      text-align: justify;
      color: $black;
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 155.023%; /* 31.005px */
    }
    @media (max-width: 1224px) {
      gap: 15px;
      p {
        text-align: justify;
        font-size: 12px;
      }
    }
    @media (max-width: 420px) {
      gap: 10px;
      p {
        font-size: 12px;
      }
    }
  }
}
