@import "../variables";

.brandCarousel {
  padding: 30px 0px;
  background-color: $gray;
  @media (max-width: 1024px) {
    padding: 20px 0px;
  }
  @media (max-width: 900px) {
    padding: 15px 0px;
  }
  @media (max-width: 650px) {
    padding: 10px 0px;
  }
}

.carousel {
  position: relative;
  overflow: hidden;
  width: 100% !important;

  :global {
    .slick-list {
      display: flex;
      margin-left: -6px !important;
      margin-right: -6px !important;
    }
    .slick-track {
      display: flex;
      flex: 1 1 0 !important;

      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
    }
    .slick-slide {
      margin: 0px 10px;
      @media (min-width: 767px) {
        margin: 0px 5px;
      }
      @media (max-width: 767px) {
        margin: 0px 3px;
      }
    }
    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }
}
.sectionName {
  color: $black;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: clamp(20px, calc(1.25rem + ((1vw - 3.2px) * 2.3438)), 40px);
  font-style: normal;
  font-weight: 600;
  line-height: 139.023%; /* 38.926px */
  margin-bottom: 20px;
  @media (max-width: 900px) {

    margin-bottom: 10px;
  }
  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
 
}
.image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
  width: 100%;
  background-color: $white;
  img {
    height: 100%;
    width: auto;
    object-fit: cover;
  }
  @media (max-width: 768px) {
    height: 70px;
  }
}
