@import "../variables";

.header {
  position: fixed;
  width: 100%;
  z-index: 7;
}
.top {
  padding: 2px 0;
  background-color: $white;
  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 1085px) {
    display: none;
  }
}
.logo {
  cursor: pointer;
  height: 33px;
  @media (max-width: 768px) {
    height: 40px;
  }
}
.navList {
  ul {
    display: flex;
    flex-direction: row;
    gap: 25px;
    transition: transform 0.8s ease;
    @media (max-width: 1320px) {
      gap: 15px;
    }
    a {
      font-family: "Noto Sans", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $black;
      &:hover {
        color: $green;
      }
    }
  }
}
.interactions {
  display: flex;
  flex-direction: row;
  gap: 50px;
  @media (max-width: 1320px) {
    gap: 25px;
  }
  @media (max-width: 1224px) {
    gap: 15px;
  }
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    @media (max-width: 1224px) {
      gap: 7px;
    }
    a {
      font-family: "Noto Sans", sans-serif;
      font-size: 15px !important;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $black;
      &:hover {
        color: $green;
      }
    }
    p {
      font-family: "Noto Sans", sans-serif;
      font-size: 15px !important;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      color: $ashen;
      &:hover {
        color: $green;
      }
    }
  }
}
.bottom {
  padding-top: 20px;
  position: relative;
  @media (max-width: 767px) {
    padding-top: 10px;
  }
  &__wrapper {
    padding: 2px 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    z-index: 2;
    box-shadow: 0 0 16px 6px #aaaaaa65;
    border-radius: 30px;
    background: #f4f5f9;
    overflow: hidden;
    @media (max-width: 1024px) {
      padding: 7px;
    }
    @media (max-width: 768px) {
      padding: 6px;
      border-radius: 15px;
    }
  }
}
.bottomActive {
  border: none !important;
  .bottom__wrapper {
    box-shadow: none !important;
  }
}
.logoBm {
  display: flex;
  img {
    height: 75px;
    @media (max-width: 1224px) {
      height: 70px;
    }
    @media (max-width: 768px) {
      height: 43px;
    }
  }
}
.catalog {
  cursor: pointer;
  width: 30%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: none;
  white-space: nowrap;
  svg {
    height: 32px;
  }
  p {
    color: #717171;
    font-family: "Noto Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 139.023%; /* 27.805px */
    text-transform: uppercase;
  }
  @media (max-width: 1250px) {
    display: none;
  }
}
.catalogActive {
  p {
    color: #6cd1ef;
  }
  svg {
    path {
      stroke: #6cd1ef;
    }
  }
}
.search {
  max-width: 768px;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 30px;
  border: 1px solid $gray;
  input {
    border-radius: 30px;
    border: 1px solid #717171;
    flex: 1 1 0;
    overflow-x: hidden;
    width: 500px;
    padding: 15px 130px 15px 50px;
    border-radius: 30px;
    background: $white;
    color: $black;
    font-family: "Noto Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &::placeholder {
      font-family: "Noto Sans", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #717171;
      @media (max-width: 1224px) {
        font-size: 12px;
      }
    }
    @media (max-width: 1224px) {
      font-size: 12px;
      padding: 15px 130px 15px 40px;
    }
  }
  button {
    position: absolute;
    right: 0;
    font-family: "Noto Sans", sans-serif;
    background-color: $green;
    padding: 16px 40px;
    text-align: center;
    font-family: "Noto Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    color: $white;
    @media (max-width: 1224px) {
      font-size: 16px;
    }
    @media (max-width: 768px) {
      padding: 20px;
    }
  }
  svg {
    height: 20px;
    position: absolute;
    left: 28px;
    top: 50%;
    @media (max-width: 1224px) {
      left: 27px;
    }
    transform: translate(-50%, -50%);
  }
  @media (max-width: 767px) {
    display: none;
  }
}
.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    gap: 15px;
  }
  @media (max-width: 767px) {
    gap: 10px;
  }
  &__favorites {
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: flex-end;
    svg {
      position: relative;
      height: 25px !important;
      width: 25px !important;
    }
    div {
      position: absolute;
      top: 0;
      z-index: 1;
      right: 0;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      border: 1px solid $green;
      display: flex;
      flex-direction: row;
      background-color: #fff;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      span {
        color: $green;
        text-align: center;
        font-family: "Noto Sans", sans-serif;

        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  &__basket {
    position: relative;
    width: 30px;
    height: 30px !important;
    display: flex;
    align-items: flex-end;
    svg {
      position: relative;
      height: 30px !important;
      width: 30px !important;
    }
    div {
      position: absolute;
      top: 0;
      z-index: 1;
      right: 0;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      border: 1px solid $green;
      display: flex;
      flex-direction: row;
      background-color: #fff;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      span {
        color: $green;
        text-align: center;
        font-family: "Noto Sans", sans-serif;

        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  &__burger {
    display: none;
    width: 34px;
    height: 30px;
    @media (max-width: 1224px) {
      display: flex;
    }
  }
  &__number {
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    @media (max-width: 1224px) {
      gap: 4px;
    }
    @media (max-width: 930px) {
      display: none;
    }
    a {
      color: #717171;
      font-family: "Noto Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @media (max-width: 1224px) {
        font-size: 15px;
      }
    }
    svg {
      height: 20px;
    }
  }
  &__lang {
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      display: none;
    }
    p {
      color: #717171;
      text-align: center;
      font-family: "Noto Sans";
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 157.023%; /* 23.553px */
    }
  }
  &__searchMb {
    display: none;
    position: relative;
    align-items: center;

    div {
      cursor: pointer;
      position: absolute;
      right: 0;
      /* height: 100% !important; */
      width: 300px;
      background-color: $white;
      border-radius: 20px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding-right: 10px;
      overflow: hidden;
      border: 1px solid $green;

      @media (max-width: 550px) {
        width: 250px;
      }
      @media (max-width: 500px) {
        width: 230px;
      }

      svg {
        width: 20px;
        height: 20px;
        z-index: 2;
        margin: 5px;
      }
      input {
        padding: 7px 10px 7px 15px;
        height: 100%;
        width: 100%;
        background: $white;
        font-family: "Noto Sans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $black;
      }
      &::placeholder {
        font-family: "Noto Sans", sans-serif;
        font-size: 14px;

        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $ashen;
      }
    }

    @media (max-width: 420px) {
      display: flex;
      &__mainSvg {
        display: flex;
      }
    }
    @media (max-width: 767px) {
      display: flex;
    }
  }
}
.serchOpen {
  overflow: auto;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  z-index: 11;
  box-shadow: 0px 2px 4px 0px rgba(51, 196, 223, 0.2);
  &__wrapper {
    position: absolute;
    background: var(--Dark-blue, #3f4867c1);
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  &__contant {
    overflow: auto;
    left: 25%;
    width: 60%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: $white;
    padding: 10px 0;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    &::-webkit-scrollbar {
      width: 6px;
      height: calc(100% - 50px);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: none;
    }
    @media (max-width: 1024px) {
      left: 15%;
      width: 70%;
      height: 80vh;
    }
    @media (max-width: 768px) {
      width: 80%;
      left: 10%;
    }
    @media (max-width: 600px) {
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}
.serchClose {
  display: none;
}
.serchOpenImage {
  overflow: hidden;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid $green;
  background-color: $white;

  img {
    width: auto;
    object-fit: cover;
    overflow: hidden;
    height: 100%;
  }
  @media (max-width: 820px) {
    width: 40px;
    height: 40px;
  }
}
.serchOpenItem {
  padding: 5px 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: $blue;
    p {
      color: $white;
    }
  }
}
.serchOpenProduct {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.serchOpenDescriptions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media (max-width: 820px) {
    gap: 0px;
  }
}
.serchOpenTitle {
  color: $black;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 139.023%; /* 25.024px */
  @media (max-width: 820px) {
    font-size: 14px;
    height: 25px;
    width: 100%;
    overflow: hidden;
  }
}
.serchOpenTitlePost {
  height: 40px;
  overflow: hidden;
  color: $black;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 139.023%; /* 25.024px */
  @media (max-width: 820px) {
    font-size: 13px;
    height: 20px;
    width: 100%;
    overflow: hidden;
  }
}
.serchOpenArticle {
  color: #686877;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 139.023%; /* 25.024px */
}
.serchOpenElement {
  text-decoration: underline;
  color: #686877;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 139.023%; /* 25.024px */
}
.serchOpenEmpty {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: #686877;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 139.023%; /* 25.024px */
}
.catalogOpen {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  z-index: 15;
  box-shadow: 0px 2px 4px 0px rgba(51, 196, 223, 0.2);
  &__wrapper {
    position: absolute;
    background: var(--Dark-blue, #3f4867c1);
    width: 100%;
    height: 100%;
  }

  &__contant {
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    padding: 20px 0;
    margin-left: 50px;
    position: absolute;
    display: flex;
    flex-direction: row;
    background-color: $white;
    border: 1px solid $green;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  &__sections {
    width: 250px;
    display: flex;
    flex-direction: column;
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      &:hover {
        background-color: $blue;
        p {
          color: $white;
        }
      }
      p {
        width: 250px;
        padding: 15px;
        color: $black;
        font-family: "Noto Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-bottom: 1px solid $gray;
      }
    }
  }
  &__category {
    display: flex;
    width: 100%;
    flex-direction: column;
    p {
      padding: 15px 30px;
      cursor: pointer;
      color: $black;
      font-family: "Noto Sans", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-bottom: 1px solid $gray;
      &:hover {
        background-color: $blue;
        color: $white;
      }
    }
  }
}
.catalogClose {
  display: none;
}
.burgerMenu {
  z-index: 5;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100%;
  display: flex;

  justify-content: flex-start;
}
.menuContent {
  overflow: auto;
  z-index: 4;
  width: 100%;
  height: 100% !important;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease;
  display: flex;
  flex-direction: column;
  padding: 0 0 52px 0px;
  &__header {
    padding: 22px 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $green;
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 13px;
    }
    p {
      color: var(--White, #fff);
      font-family: "Noto Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 139.023%; /* 25.024px */
    }
    svg {
      height: 20px;
      width: 20px;
      &:last-child {
        cursor: pointer;
        height: 15px;
        width: 15px;
      }
    }
  }

  &__navList {
    width: 100% !important;

    display: flex;
    justify-content: flex-end;
    width: 350px;
    border-top: 1px solid $gray;
    @media (max-width: 475px) {
      width: 300px;
    }
    ul {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: start;
      padding: 0 21px;

      li {
        padding: 13px 8px 16px 4px;
        width: 100% !important;
        border-bottom: 1px solid #d0d0d0;

        a {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          color: #000;

          font-family: "Noto Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 139.023%; /* 25.024px */
        }
      }
    }
  }
}
.close {
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 35px;
  width: 20px;
  height: 20px;
  @media (max-width: 768px) {
    top: 15px;
    right: 25px;
  }
}
.searchMb {
  display: none;
}
.searchMbStatick {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  input {
    width: 300px;
    background-color: $white;
    border-radius: 20px;
  }
}
.activ {
  display: flex !important;
}
.hidden {
  display: none !important;
}
.langActive {
  cursor: default !important;
  color: $green !important;
  font-weight: 700 !important;
}
.headerWithBackground {
  background: var(--Dark-blue, #3f4867c1);
}
.islandBotton {
  @media (min-width: 768px) {
    display: none;
  }
  margin: 0 auto;
  width: calc(100% - 20px);
  display: flex;
  margin-top: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &__lang {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    p {
     
      color: #717171;
      text-align: center;
      font-family: "Noto Sans";
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 157.023%; /* 23.553px */
      cursor: pointer !important;
    }
  }
  &__number {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    svg {
      height: 15px;
      width: 15px;
    }
    a {
      color: #fff;
      font-family: "Noto Sans";
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  &__action {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
  &__favorites {
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: flex-end;
    svg {
      position: relative;
      height: 25px !important;
      width: 25px !important;
    }
    div {
      position: absolute;
      top: 0;
      z-index: 1;
      right: 0;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      border: 1px solid #fff;
      display: flex;
      flex-direction: row;
      background-color: $green;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      span {
        color: #fff;
        text-align: center;
        font-family: "Noto Sans", sans-serif;

        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  &__basket {
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: flex-end;
    svg {
      position: relative;
      height: 32px !important;
      width: 30px !important;
    }
    div {
      position: absolute;
      top: 0;
      z-index: 1;
      right: 0;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      border: 1px solid #fff;
      display: flex;
      flex-direction: row;
      background-color: $green;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      span {
        color: #fff;
        text-align: center;
        font-family: "Noto Sans", sans-serif;

        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
.islandBottonLangActive {
  color: #fff !important;
}
.searchOpenActiveMb {
  background-color: #fff;
  .bottom__wrapper {
    box-shadow: none;
  }
}
