.home {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.contant {
  margin-top: 95px;
  @media (max-width: 1086px) {
    margin-top: 64px;
  }
  @media (max-width: 768px) {
    margin-top: 54px;
  }
  @media (max-width: 650px) {
    margin-top: 45px;
  }
}
.widget {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  bottom: 13%;
  right: 6%;
  z-index: 10 !important;
  @media (max-width: 1024px) {
    gap: 10px;
  }
}
