@import "../variables";

.bannerProductRange {
  height: fit-content;
  background-color: $white;
  padding: 30px 0;
  @media (max-width: 1024px) {
    padding: 20px 0px;
  }
  @media (max-width: 900px) {
    padding: 15px 0px;
  }
  @media (max-width: 650px) {
    padding: 10px 0px;
  }
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    @media (max-width: 600px) {
      gap: 20px;
    }
  }
}
.descriptions {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  color: $black;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 139.023%; /* 38.926px */
  @media (max-width: 1224px) {
    font-size: 22px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }
 
}
.subtitle {
  color: #8fc23d;
  text-align: center;
  font-family: "Pacifico", cursive;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 139.023%; /* 30.585px */
  @media (max-width: 1224px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
}
.items {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  @media (max-width: 768px) {
    grid-column-gap: 10px;
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 380px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.item {
  cursor: pointer;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  background: rgba(53, 151, 64, 0.08);
  border-radius: 10px;
  transition: background 0.5s ease-in-out;
  @media (max-width: 1224px) {
    padding: 16px;
  }
  @media (max-width: 900px) {
    gap: 10px;
  }
  p {
    height: 37px;
    overflow: hidden;
    color: $black;
    text-align: center;
    font-family: "Noto Sans", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 115%;

    @media (max-width: 420px) {
      height: 17px;
    }
  }
  &:hover {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
    background: $green;
    p {
      color: $white;
    }
    .img {
      transform: scale(1.1);
    }
  }
  &:active {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
    background: $green;
    p {
      color: $white;
    }
  }
}
.img {
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $white;
  img {
    overflow: hidden;
    border-radius: 50%;
    height: 100%;
    width: auto;
    object-fit: cover;
  }
  @media (max-width: 620px) {
    img {
      overflow: hidden;
      border-radius: 50%;
      height: 100%;
      width: auto;
      object-fit: cover;
    }
  }
}
.hidden {
  display: none;
}
