@import "../variables";

.successStep {
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px;

  height: 300px;

  @media (min-width: 769px) {
    height: 250px;
  }
  @media (max-width: 768px) {
    height: 200px;
    margin-bottom: 20px;
  }
  @media (max-width: 360px) {
    height: 150px;
    margin-bottom: 20px;
  }
}
.successStepDescriptions {
  display: flex;
  flex-direction: column;
  gap: 0px;
  text-align: center;
  &__title {
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 139.023%;
    color: $black;
    @media (max-width: 920px) {
      font-size: 24px;
    }
    @media (max-width: 660px) {
      font-size: 20px;
    }
    @media (max-width: 440px) {
      font-size: 18px;
    }
  }
  &__description {
    text-align: center;
    color: var(--Text, #686877);
    font-family: "Noto Sans", sans-serif;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 155.023%; /* 26.354px */
    @media (max-width: 920px) {
      font-size: 12px;
    }
    @media (max-width: 660px) {
      font-size: 12px;
    }
  
  }
}
.successStepBtn {
  margin-top: 30px;
  padding: 12px 30px;
  border-radius: 25px;
  cursor: pointer;
  background-color: $green;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.32);
  svg {
    height: 15px;
  }
  p {
    color: $white;
    font-family: "Noto Sans", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &:hover {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.32);
  }
  @media (max-width: 660px) {
    margin-top: 10px;
    padding: 10px 20px;
    p {
      font-size: 11px;
    }
  }
  @media (max-width: 440px) {
    margin-top: 10px;
    padding: 7px 10px;
    p {
      font-size: 11px;
    }
  }
}
