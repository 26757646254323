@import "../variables";

.blogPostItem {
  padding: 30px 0px 70px 0px;
  @media (max-width: 1224px) {
    padding: 20px 0px 70px 0px;
  }
  @media (max-width: 1024px) {
    padding: 15px 0px 70px 0px;
  }
  @media (max-width: 850px) {
    padding: 10px 0px 70px 0px;
  }
  @media (max-width: 650px) {
    padding: 10px 0px 70px 0px;
  }
  &__container {
    @media (min-width: 1920px) {
      max-width: 1920px;
      margin: 0 auto;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 100px;
  }
}

.header {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 650px) {
    width: 90%;
    margin: 0 auto;
  }
  .img {
    width: 100% !important;
    aspect-ratio: 1920/500;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
    }
    @media (max-width: 650px) {
      aspect-ratio: 1;
      height: 200px;
      border-radius: 30px;
      overflow: hidden;
    }
  }
}
.content {
  position: absolute;
  width: fit-content;
  display: flex;
  bottom: -40px;
  padding: 36px 32px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 0 auto !important;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 14px 36px 0px rgba(204, 204, 204, 0.25);
  @media (max-width: 650px) {
    padding: 14px;
    max-width: 86%;
  }
}
.title {
  color: $black;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 139.023%; /* 38.926px */
  @media (max-width: 900px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 13px;
  }
}
.subtitle {
  color: #303030;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 155.023%; /* 31.005px */
  width: 100%;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 10px;
  }
}
.date {
  width: 100%;
  color: #303030;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  text-align: start;
  font-weight: 600;
  line-height: normal;

  @media (max-width: 600px) {
    font-size: 10px;
  }
}
.descriptions {
  display: flex !important;
  flex-direction: column;
  gap: 10px;
  padding: 0 15px;
  p {
    text-align: justify;
    color: var(--Text, #686877);
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.023%;
    ul {
      display: block !important;
    }

    @media (max-width: 650px) {
      font-size: 13px;
    }
  }
}
.contant {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0px 40px;
  @media (max-width: 600px) {
    padding: 0px 20px;
  }
}
