@import "../variables";

.contactInformations {
  padding: 30px 0px 70px 0px;
  @media (max-width: 1224px) {
    padding: 20px 0px 70px 0px;
  }
  @media (max-width: 1024px) {
    padding: 15px 0px 70px 0px;
  }
  @media (max-width: 850px) {
    padding: 10px 0px 70px 0px;
  }
  @media (max-width: 650px) {
    padding: 10px 0px 70px 0px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media (max-width: 768px) {
      gap: 20px;
    }
    @media (max-width: 660px) {
      gap: 10px;
    }
  }
}
.title {
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 139.023%;
  color: $black;
  @media (max-width: 900px) {
    font-size: 24px;
  }
  @media (max-width: 660px) {
    font-size: 20px;
  }
}
.twoPart {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (max-width: 1170px) {
    flex-direction: column-reverse;
    gap: 0px;
  }
  /* @media (max-width: 768px) {
    gap: 15px !important;
  }
  @media (max-width: 660px) {
    gap: 10px;
  } */
}
.first {
  display: flex;
  flex-direction: column;
}
.items {
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media (max-width: 1170px) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
  }
  @media (max-width: 660px) {
    gap: 10px;
  }
}
.item {
  width: 270px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.176);
  display: flex;
  flex-direction: column;
  background-color: $gray;
  border-radius: 30px;
  padding: 30px 20px;
  gap: 15px;
  @media (max-width: 768px) {
    padding: 15px 10px;
    width: 100% !important;
    align-items: center;
    border-radius: 10px;
    gap: 10px;
  }
}
.location {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  p {
    color: $black;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 139.023%;
    @media (max-width: 768px) {
      font-size: 14px;
      text-align: center;
    }
  }
  svg {
    height: 22px;
  }
}
.hours {
  display: flex;
  flex-direction: column;
  &__items {
    padding-left: 40px;
    p {
      color: $black;
      font-family: "Noto Sans", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media (max-width: 660px) {
        font-size: 12px;
      }
    }
  }
  svg {
    height: 22px;
    @media (max-width: 768px) {
      height: 17px;
    }
    @media (max-width: 420px) {
      height: 15px;
    }
  }
}
.workingHours {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  p {
    color: $black;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 139.023%;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}

.second {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  @media (max-width: 768px) {
    gap: 15px !important;
  }
}
.contactInfo {
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.176);
  background-color: $gray;
  border-radius: 30px;
  padding: 12px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    align-content: center;
    border-radius: 10px;
  }
  &__title {
    color: $black;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 139.023%;
    @media (max-width: 768px) {
      text-align: center;
      font-size: 14px;
    }
  }
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    &__number {
      display: flex;
      flex-direction: column !important;
      align-items: flex-start;
      justify-content: flex-start;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
    }
    svg {
      height: 22px;
      @media (max-width: 768px) {
        height: 17px;
      }
      @media (max-width: 420px) {
        height: 15px;
      }
    }
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 7px;
      a {
        color: $black;
        font-family: "Noto Sans", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 139.023%;
       
      }
    }
  }
}
.map {
  overflow: hidden;
 
  iframe {
    transform: translateY(-46px);
    max-width: 600px;

    /* filter: grayscale(100%); */

    @media (max-width: 768px) {
      max-width: 480px;
    }
    @media (max-width: 525px) {
      max-width: 400px;
    }
    @media (max-width: 440px) {
      max-width: 350px;
    }
    @media (max-width: 380px) {
      max-width: 300px;
    }
    @media (max-width: 330px) {
      max-width: 270px;
    }
  }
}
