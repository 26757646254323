@import "../variables";

.mainBannerCarousel {
  position: relative;
  height: fit-content;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}
.container {
  @media (min-width: 1920px) {
    margin: 0 auto;
    max-width: 1920px;
  }
}
.embla {
  position: relative;
  overflow: hidden;
}
.embla__container {
  display: flex;
  flex-direction: row;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.image {
  height: 100% !important;
  width: 100%;
  img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
  }
}
.height {
  width: 1920px !important;
  height: 920px !important;
}

