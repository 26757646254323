@import "../variables";

:root {
  --background: #f8f8ff;
  --table-background: #fcfcfd;
  --primary: #272727;
  --gray: #a0a7b1;
  --odd-row: #f6f9ff;
  --th-background: #f4f5fc;
  --gray-background: #edeef1;
  --gray-mid: #f1f2f6;
}

.adminItems {
  width: 100%;
  font-family: "Noto Sans", sans-serif;
  margin: 0px;
  display: flex;
  justify-content: center;
  background-color: var(--background);
  &__container {
    @media (min-width: 1600px) {
      margin: 0 auto;
      max-width: 90vw;
    }
    @media (max-width: 1599px) {
      width: 100%;
      margin: 0 auto;
      padding: 0px 60px;
    }
    @media (max-width: 1300px) {
      margin: 0 auto;
      padding: 0px 0px;
    }
  }
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden !important;
  }
}
.caption-container {
  font-size: 1.12rem;
  font-weight: 700;
  text-align: left;
  padding-bottom: 16px;
  border-bottom: 2px solid var(--gray-background);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.table-widget {
  overflow: hidden;
  border-radius: 16px;
  background-color: var(--table-background);
  padding: 24px;
  width: 100%;
  margin: 16px;
  max-width: 90%;
  border: 2px solid var(--gray-background);
  box-shadow: 0px 4px 16px 0px rgba(148, 156, 169, 0.15);
  text-align: left;
  @media (max-width: 1224px) {
    max-width: 98%;
  }
}
.search {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-left: 16px;

  input {
    font-size: 0.8rem;
    font-weight: 700;
    font-family: "Noto Sans", sans-serif;
    border-radius: 8px;
    font-size: 0.8rem;
    font-weight: 700;
    background-color: var(--gray-mid);
    padding: 8px 35px 8px 10px;
    height: 30px;
    width: 300px;
  }
  svg {
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 1;
    cursor: pointer;
    right: 5px;
  }
}
.table-wrapper {
  overflow-x: auto !important;
  overflow-y: auto;
  padding-bottom: 8px;
  max-height: 67vh;
}
.table-wrapper thead {
  position: sticky !important;
}
.table-widget table {
  max-width: 600px;
  border-collapse: collapse;
  table-layout: fixed;
}
.tableHeader {
  z-index: 114;
}
.tableHeader th {
  position: sticky !important;
  top: 0;
  background-color: #f4f5fc; /* или другой желаемый цвет фона */
  z-index: 10 !important; /* чтобы заголовок оставался поверх других элементов */
}
.tableHeader th:first-child {
  z-index: 11 !important; /* чтобы заголовок оставался поверх других элементов */
}
.table-widget th {
  cursor: pointer;
  padding: 8px 8px 8px 8px;
  font-size: 0.875rem;
  color: #000;
  width: fit-content;
  white-space: nowrap;
  background-color: var(--th-background);
  text-align: center;

  span {
    margin: 0 1px;
  }
}
.table-widget tfoot {
  margin-top: 16px;
  border-top: 2px solid var(--gray-background);
}
.table-widget td {
  padding: 8px 8px 8px 8px;
  vertical-align: middle;
  /* white-space: nowrap; */
  border-bottom: 2px solid var(--gray-background);
  font-size: 14px !important;
}
.caption-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start !important;
  gap: 20px;
  padding-bottom: 30px;
}
.table-row-count {
  font-size: 0.8rem;
  font-weight: 700;
  background-color: var(--gray-mid);
  padding: 8px;
  display: inline-flex;
  align-items: center;
  margin-left: 16px;
  border-radius: 8px;
  white-space: nowrap;
}
.stock-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
.stock-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.stock-info__ticker {
  font-weight: 700;
}
.stock__name {
  font-weight: 700;
  white-space: nowrap;
}

.table-widget {
  min-height: 80px;
}
.sticky-left {
  font-weight: 700;
  font-size: 10px;
  text-transform: uppercase;
  color: #555555;
  position: sticky;
  z-index: 1 !important;
  top: 0;
  left: 0;
  background-color: #f4f5fc;
}
.sticky-right {
  position: sticky;
  z-index: 1;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  background-color: var(--table-background);
  padding-bottom: 2px;
}
.caption-container {
  display: flex;
  align-items: center;
}

.table-title {
  display: flex;
  gap: 12px;
}
.price {
  font-weight: 600;
}
.up {
  color: #2ecc71;
}
.down {
  color: #e3452f;
}
.sticky-right button {
  border-radius: 12px;
  background-color: var(--gray-mid);
  color: var(--primary);
  outline: none;
  font-weight: 700;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 10px 16px;
}
.sticky-right button:nth-child(1):hover {
  background-color: red;
}
.sticky-right button:nth-child(1):hover path {
  fill: #fff;
}
.sticky-right a:nth-child(2):hover {
  button {
    background-color: black;
  }
}
.sticky-right button:nth-child(2):hover {
  svg {
    fill: #fff;
  }
}
.table-wrapper::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
.table-wrapper::-webkit-scrollbar-track {
  background: var(--gray-mid);
  border-radius: 6px;
}
.table-wrapper::-webkit-scrollbar-thumb {
  background: var(--gray);
  border-radius: 5px;
}
.pagination {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalTools {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 3;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.modalClose {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.saveData {
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 6px;
  color: #fff;
  background: rgb(73, 69, 255);
}
.modalContent {
  position: absolute;
  border-radius: 16px;
  background-color: var(--table-background);
  padding: 24px;
  width: 100%;
  margin: 16px;
  max-width: 90%;
  border: 2px solid var(--gray-background);
  box-shadow: 0px 4px 16px 0px rgba(148, 156, 169, 0.15);

  z-index: 3;
  top: 80px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tools {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center !important;
  gap: 10px;
  input {
    cursor: pointer;
  }
  @media (max-width: 950px) {
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: baseline !important;
  }
}
.sortData {
  display: flex;
  align-items: center !important;
  gap: 3px;

  p {
    font-weight: 700;
    font-size: 14px;
    color: #000;
  }
  div {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    gap: 5px;
    span {
      font-weight: 600;
      font-size: 14px;
      color: #000;
    }
    input {
      border-radius: 10px;
      background-color: var(--table-background);
      border: 2px solid var(--gray-background);
      box-shadow: 0px 4px 16px 0px rgba(148, 156, 169, 0.15);
      padding: 3px 7px;
      font-weight: 500;
      font-size: 14px;
      width: 120px;
    }
  }
}

.table-widget tr th:nth-child(1),
.table-widget tr th:nth-child(2),
.table-widget tr th:nth-child(3),
.table-widget tr th:nth-child(5),
.table-widget tr th:nth-child(10),
.table-widget tr th:nth-child(11),
.table-widget tr th:nth-child(12),
.table-widget tr th:nth-child(13),
.table-widget tr th:nth-child(14),
.table-widget tr th:nth-child(16),
.table-widget tr td:nth-child(1),
.table-widget tr td:nth-child(2),
.table-widget tr td:nth-child(3),
.table-widget tr td:nth-child(5),
.table-widget tr td:nth-child(10),
.table-widget tr td:nth-child(11),
.table-widget tr td:nth-child(12),
.table-widget tr td:nth-child(13),
.table-widget tr td:nth-child(14),
.table-widget tr td:nth-child(15),
.table-widget tr td:nth-child(16) {
  text-align: center;
}

.sortable-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  svg {
    height: 16px !important;
    width: 18px !important;
    path {
      height: 16px !important;
      width: 18px !important;
    }
  }
}
.addNewOrder {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 13px;
  gap: 7px;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 6px;
  color: #fff;
  background: rgb(73, 69, 255);
}
.confirmOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2px);
  z-index: 128;
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirmContent {
  position: fixed;
  border-radius: 16px;
  background-color: var(--table-background);
  padding: 24px;
  width: 500px;
  margin: 16px;
  max-width: 90%;
  border: 1px solid #000;
  box-shadow: 0px 4px 16px 0px rgba(148, 156, 169, 0.15);
  overflow: hidden;
  z-index: 130;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  p {
    text-align: center;
  }
}
.confirmBtn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.confirmTitle {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding: 10px;
  color: #000;
}
.confirmDescriptions {
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
  padding: 10px;
  color: #000;
}
.deleteData {
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 6px;
  color: #fff;
  background: rgb(239, 32, 32);
}