@import "../variables";

.bannerAdvantages {
  padding: 30px;
  @media (max-width: 1224px) {
    padding: 20px;
  }
  @media (max-width: 1024px) {
    padding: 15px;
  }
  @media (max-width: 850px) {
    padding: 10px;
  }
  @media (max-width: 650px) {
    padding: 10px;
  }
}
.items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (min-width: 1445px) {
    justify-content: space-around;
  }
  @media (max-width: 670px) {
    justify-content: space-around;
  }
}
.item {
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 850px) {
    gap: 0;
  }
  svg {
    height: 35px;
    @media (max-width: 850px) {
      height: 30px;
    }
    @media (max-width: 670px) {
      display: none;
    }
    @media (max-width: 560px) {
      display: flex;
    }
    @media (max-width: 340px) {
      height: 25px;

    }
  }
  div {
    display: flex;
    flex-direction: column;
    gap: 2px;
    p {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 139.023%; /* 25.024px */
      text-transform: uppercase;
      font-family: "Noto Sans", sans-serif;
      color: $black;
      @media (max-width: 1224px) {
        font-size: 14px;
      }
      @media (max-width: 850px) {
        font-size: 10px;
      }
      @media (max-width: 700px) {
        font-size: 10px;
      }
      @media (max-width: 670px) {
        color: $ashen;
        text-align: center;
      }
      @media (max-width: 560px) {
        display: none;
      }
    }
  }
}
