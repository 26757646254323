@import "../variables";

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 4px solid $green; 
  width: 40px; 
  height: 40px; 
  animation: spin 1s linear infinite; /* Анимация вращения */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
