@import "../variables";

.contactUs {
  background: #8fc23d;
  position: relative;
  margin: 55px 0;
  @media (max-width: 767px) {
    margin: 0;
  }
  &__wrapper {
    /* background: #8fc23d; */
    height: 550px;
    overflow: hidden;
    position: relative;
    @media (max-width: 767px) {
      height: 530px;
      padding-bottom: 150px;
    }
  }
}
.container {
  margin: 0 auto;

  @media (min-width: 1921px) {
    max-width: 1920px;
  }
  @media (max-width: 1920px) {
    max-width: 1920px;
  }
}
.twoPart {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    padding-bottom: 50px;
  }
}
.image {
  height: 100%;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  @media (max-width: 1224px) {
    left: -100px !important;
  }
  @media (max-width: 767px) {
    width: 100vw !important;
    bottom: 0;
    max-height: 180px !important;

    left: auto !important;
  }
  img {
    overflow: hidden;
    height: auto;
    width: 68%;
    object-fit: cover;

    @media (max-width: 1400px) {
      height: 100%;
      width: auto;
    }
    @media (max-width: 767px) {
      width: 100% !important;
      height: 100%;
      z-index: 1;
    }
  }
}
.descriptions {
  right: 200px;
  position: relative;
  padding-right: 30px;
  z-index: 2;
  width: 500px !important;
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  @media (max-width: 1700px) {
    right: 150px;
  }
  @media (max-width: 1525px) {
    right: 70px;
  }
  @media (max-width: 1400px) {
    right: 0px;
  }
  @media (max-width: 1400px) {
    padding: 6px 20px;
    width: 100%;
    height: 500px;
  }
}
.ellips {
  right: -150px;
  position: absolute;
  z-index: 1;
  width: 1000px;
  height: 1000px;
  border-radius: 50%;
  background-color: $green;
  @media (max-width: 1700px) {
    right: -250px;
  }
  @media (max-width: 1525px) {
    right: -350px;
  }
  @media (max-width: 1400px) {
    right: -450px;
  }
  @media (max-width: 767px) {
    top: -1580px; //1620
    right: auto;
    width: 2000px;
    height: 2000px;
  }
  @media (max-width: 415px) {
    top: -1550px; //1620
    right: auto;
    width: 2000px;
    height: 2000px;
  }
}
.description {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.title {
  color: $black;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 38.926px */
}
.subtitle {
  color: #787878;
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  width: 350px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 24.804px */
}
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.inputs {
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-items: center;
  @media (max-width: 450px) {
    input {
      width: 320px !important;
    }
  }
  input {
    transition: transform 0.3s ease-in-out;

    padding: 10px 20px;
    width: 350px;
    border-radius: 40px;
    border: 1px solid var(--Blue, #74ccd8);
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: $black;
    line-height: normal;
    background: var(--White, #fff);
    &::placeholder {
      color: var(--Gray-3, #b7b8c5);
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &:hover {
      color: $black;
      border: 1px solid $gray;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
    }
    &:focus {
      color: $black;
      border: 1px solid $gray;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
    }
  }
}
.message {
  border: 1px solid $gray;
  border-radius: 20px;
  overflow: hidden;
  width: 350px;
  height: 130px;
  @media (max-width: 450px) {
    width: 320px !important;
  }
  textarea {
    transition: transform 0.3s ease-in-out;
    overflow: auto;
    resize: none;
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: $black;
    line-height: normal;

    &:hover {
      color: $black;
      border: 1px solid $gray;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
    }
    &:focus {
      color: $black;
      border: 1px solid $gray;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
    }
    &:active {
      color: $black;
    }
    &::placeholder {
      color: var(--Gray-3, #b7b8c5);
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  textarea::-webkit-scrollbar {
    width: 4px;
    margin-top: 20px;
  }
  /* Цвет полосы прокрутки */
  textarea::-webkit-scrollbar-thumb {
    background-color: $gray;
  }
  /* Цвет фона полосы прокрутки */
  textarea::-webkit-scrollbar-track {
    background-color: none;
  }
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.note {
  color: #787878;
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  width: 350px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.023%; /* 18.603px */
  @media (max-width: 767px) {
    display: none;
  }
}
.btn {
  cursor: pointer;
  display: inline-flex;
  padding: 7px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid #fff;
  box-shadow: 0px 2px 4px 0px rgba(51, 196, 223, 0.2);
  transition: transform 0.3s ease-in-out;

  p {
    color: $white;
    font-family: "Noto Sans", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  svg {
    width: 20px;
    height: 20px;
  }
  &:hover {
    transition: transform 0.5s ease-in-out;

    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
}
.errorInput {
  background-color: #ffebee !important;
  &::placeholder {
    color: rgb(153, 23, 23) !important;
    font-weight: 600 !important;
  }
}
.ellipsFirst {
  position: absolute;
  top: -20px;
  left: 120px;
  width: 70px;
  height: 70px;
  @media (max-width: 767px) {
    display: none;
  }
}
.ellipsSecond {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  width: 70px;
  height: 70px;
  @media (max-width: 1100px) {
    right: 150px;
  }
  @media (max-width: 767px) {
    display: none;
  }
}
