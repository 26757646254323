@import "../variables";

.bannerDispatch {
  overflow: hidden;
  background-color: #dcf6b3;
  &__container {
    @media (min-width: 1920px) {
      max-width: 1920px;
      margin: 0 auto;
    }
  }
  &__wrapper {
    position: relative;
    overflow: hidden;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column-reverse;
      background-color: #dcf6b3;
    }
  }
}
.contant {
  padding: 30px 0;
  height: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  left: 20%;
  position: relative;
  z-index: 4;
  @media (max-width: 1100px) {
    left: 10%;
  }
  @media (max-width: 930px) {
    left: 2%;
  }
  @media (max-width: 768px) {
    align-items: center;
    left: auto;
    width: 100%;
  }
  @media (max-width: 500px) {
    padding: 10px 0;
    top: -20px;
  }
  @media (max-width: 430px) {
    top: -10px;
  }
}
.title {
  color: $black;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 139.023%; /* 38.926px */
  @media (max-width: 768px) {
    text-align: center;
    width: 90%;
    font-size: 18px;
  }
}
.subtitle {
  color: #393f7b;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.023%; /* 24.804px */
  @media (max-width: 768px) {
    text-align: center;
    width: 60%;
    font-size: 10px;
  }
}
.form {
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  input {
    width: 350px;
    padding: 10px 20px;
    border-radius: 34px;
    border: 1px solid #50909c;
    background: var(--White, #fff);
    @media (max-width: 768px) {
      text-align: center;
      width: 100%;
    }
  }
}

.btn {
  z-index: 4;
  cursor: pointer;
  padding: 10px 25px;
  background-color: $green;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
  }
  &:hover {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
  &:active {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
  svg {
    width: 10px;
    height: 10px;
  }
  p {
    color: $white;
    font-family: "Noto Sans", sans-serif;
    font-size: smaller;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.ellips {
  height: 200px;
  width: 80%;
  z-index: 1;
  top: 0;
  left: 0;
  position: absolute;
  @media (max-width: 768px) {
    display: none;
  }
}
.ellipsMB {
  @media (min-width: 769px) {
    display: none;
  }
  @media (max-width: 768px) {
    width: 100%;
    z-index: 1;
    top: 80px !important;
    position: absolute;
    object-fit: cover;
  }
  @media (max-width: 650px) {
    top: 116px !important;
  }
  @media (max-width: 500px) {
    top: 96px !important;
  }
  @media (max-width: 430px) {
    top: 116px !important;
  }
}
.image {
  height: 200px;
  z-index: 0;
  top: 0;
  right: 0;
  position: absolute;
  @media (max-width: 768px) {
    position: relative;
    height: 150px;
    object-fit: cover;
    width: auto;
  }
}
.vectorTwo {
  z-index: 2;
  top: -10px;
  left: 7%;
  position: absolute;
  height: 150px;
  @media (max-width: 768px) {
    left: -15%;
    top: -20px;
    display: none;
  }
}
.vectorOne {
  z-index: 2;
  bottom: -20px;
  left: 70%;
  position: absolute;
  height: 70px;
  width: 70px;
  @media (max-width: 768px) {
    left: auto;
    right: -4px;
    top: 160px;
  }
}
.vectorThree {
  z-index: 2;
  bottom: -40px;
  left: 35%;
  position: absolute;
  transform: rotate(180deg);
  @media (max-width: 768px) {
    bottom: 90px;
    left: -80px;
    transform: rotate(270deg);
  }
}
.vectorThreeDuble {
  z-index: 2;
  top: -30px;
  left: 50%;
  position: absolute;
  @media (max-width: 768px) {
    display: none;
  }
}
.vectorTwoMb {
  position: absolute;
  z-index: 7;
  width: 48.251px;
  height: 77.507px;
  top: 0;
  display: none;
}
