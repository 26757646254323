@import "../variables";

.catalogItems {
  padding: 10px 0px 70px 0px;
  @media (max-width: 650px) {
    padding: 5px 0px 70px 0px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: 1224px) {
      gap: 15px;
    }
  }
}
.items {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
.item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 768px) {
    gap: 15px;
  }
}
.filter {
  @media (max-width: 1024px) {
    display: none;
  }
}
.filterMb {
  @media (min-width: 1025px) {
    display: none;
  }
  @media (max-width: 660px) {
    width: fit-content;
  }
}
.filterMbRelativ {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 7px 35px 7px 35px;
  border-radius: 60px;
  border: 1px solid $gray;
  background-color: $white;
  cursor: pointer;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.32);
  &:hover {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
  &:active {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
  p {
    color: $black;
    font-family: "Noto Sans", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.64px;
    line-height: 20px;
  }
}
.arrow {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 100%;
  }
}
.dropDown {
  background-color: $white !important;
  overflow: scroll;
  z-index: 9;
  width: fit-content;
  left: 0;
  position: fixed;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.32);
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
}
.interaction {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 1025px) {
    justify-content: flex-end;
  }
  @media (max-width: 1024px) {
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    margin-right: 50px;
  }

  @media (max-width: 660px) {
    justify-content: space-around;
    margin-right: 0;
  }
}
.categorySearchName {
  color: var(--Text, #686877);
  font-family: "Noto Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 139.023%; /* 30.585px */
  span {
    color: $black;
    font-family: "Noto Sans", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 139.023%; /* 30.585px */
  }
  @media (max-width: 1224px) {
    font-size: 15px;
    span {
      font-size: 17px;
      line-height: 139.023%; /* 30.585px */
    }
  }
  @media (max-width: 768px) {
    font-size: 13px;
    span {
      font-size: 14px;
      line-height: 139.023%; /* 30.585px */
    }
  }
}
.overlay {
  top: 0;
  left: 0;
  z-index: 8;
  position: fixed;
  opacity: 0.9;
  background: var(--Dark-blue, #3f4867);
  width: 100%;
  height: 100%;
}
.close {
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 20px;
  height: 20px;
  @media (max-width: 768px) {
    top: 15px;
    right: 25px;
  }
}
