@import "../variables";

.sort {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &__wrapper {
    width: 255px;
    position: relative;
    @media (max-width: 1024px) {
      width: fit-content;
    }
  }
}
.sortBy {
  position: relative;
  z-index: 3;
  padding: 10px 20px 10px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 60px;
  border: 1px solid $gray;
  background-color: $white;
  cursor: pointer;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.32);
  &:hover {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
  &:active {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
  @media (max-width: 1024px) {
    padding: 7px 20px 7px 25px;
    width: 100%;
    gap: 10px;
  }
  @media (max-width: 350px) {
    padding: 7px 15px 7px 20px;
  }
}
.description {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.title {
  color: #70737c;
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  @media (max-width: 1024px) {
    font-weight: 700;
    color: $black;
  }
}
.selected {
  color: $black;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 156.25% */
  letter-spacing: -0.64px;
  @media (max-width: 1024px) {
    display: none;
  }
}
.arrow {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 100%;
  }
}
.dropDown {
  overflow: hidden;
  top: 25px;
  width: 100%;
  z-index: 2;
  position: absolute;
  border-radius: 0 0 10px 10px;
  padding: 25px 0px 10px 0px;
  background-color: $white;
  border: 1px solid $gray;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.32);
  @media (max-width: 1024px) {
    width: 200px;
    top: 42px;
    right: 0;
    padding: 0;
    border-radius: 10px;
    p {
      padding: 5px 15px;
      text-align: end;
    }
  }
  p {
    width: 100%;
    padding: 5px 25px;
    cursor: pointer;
    color: $black;
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 156.25% */
    letter-spacing: -0.64px;
    &:hover {
      color: $white;
      background-color: #8fc23d6d;
    }
  }
  .selectedItem {
    color: $white;
    background-color: $green;
  }
}
