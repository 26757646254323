@import "../variables";

.stepper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: 1024px) {
    gap: 25px;
  }
  @media (max-width: 768px) {
    gap: 20px;
  }
  @media (max-width: 660px) {
    gap: 10px;
  }
}
.stepsContainer {
  position: relative;
  margin: 0 50px;
}

.stepsWrapper {
  width: 100%;
  display: flex;
  align-items: center;
}
.steps {
  width: 100%;
  position: relative;
  padding: 5px 15px;
  border-radius: 10px;
  background-color: $gray;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.2s ease;
  @media (max-width: 1024px) {
    padding: 4px 10px;
  }
  @media (max-width: 768px) {
    height: 20px;
  }
}
.steps.active {
  background-color: $green;
  color: $white;
  .stepNumber {
    background-color: $green;
    color: $white;
  }
  .stepName {
    color: $white;
  }
}
.stepName {
  margin-left: 15px;
  color: #686877;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 139.023%; /* 22.244px */
  @media (max-width: 1024px) {
    font-size: 12px;
  }
  @media (max-width: 768px) {
    display: none;
  }
}
.stepNumber {
  z-index: 1;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid $white;
  background-color: $gray;
  left: -20px;
  position: absolute;
  color: #686877;
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 139.023%; /* 25.024px */
  @media (max-width: 1024px) {
    width: 35px;
    height: 35px;
    font-size: 14px;
  }
  @media (max-width: 550px) {
    width: 30px;
    height: 30px;
  }
}
