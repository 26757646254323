.catalog {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  .contant {
    margin-top: 100px;
    @media (max-width: 1024px) {
      margin-top: 114px;
    }
    @media (max-width: 768px) {
      margin-top: 75px;
    }
   
  }
  .widget {
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    bottom: 13%;
    right: 6%;
    z-index: 9 !important;
    @media (max-width: 1024px) {
      gap: 10px;
    }
  }
  