@import "../variables";

.favoritesItem {
  padding: 30px 0px 70px 0px;
  @media (max-width: 1224px) {
    padding: 20px 0px 70px 0px;
  }
  @media (max-width: 1024px) {
    padding: 15px 0px 70px 0px;
  }
  @media (max-width: 850px) {
    padding: 10px 0px 70px 0px;
  }
  @media (max-width: 650px) {
    padding: 10px 0px 70px 0px;
  }
}
.title {
  color: $black;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 139.023%; /* 38.926px */
  margin-bottom: 20px;
  @media (max-width: 900px) {
    font-size: 20px;
    margin-bottom: 10px;
  }

}
.items {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  @media (max-width: 1575px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: 1385px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 580px) {
    grid-template-columns: repeat(2, 1fr);
     
  }
}
