@import "../variables";

.catalogCategoryDetails {
  padding: 30px 0px 70px 0px;
  @media (max-width: 1224px) {
    padding: 20px 0px 70px 0px;
  }
  @media (max-width: 1024px) {
    padding: 15px 0px 70px 0px;
  }
  @media (max-width: 850px) {
    padding: 10px 0px 70px 0px;
  }
  @media (max-width: 650px) {
    padding: 10px 0px 70px 0px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media (max-width: 1024px) {
      gap: 20px;
    }
    @media (max-width: 660px) {
      gap: 10px;
    }
  }
}
.title {
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 139.023%;
  color: $black;
  @media (max-width: 920px) {
    font-size: 24px;
  }
  @media (max-width: 660px) {
    font-size: 20px;
  }
}
.items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  @media (max-width: 1160px) {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }
  @media (max-width: 850px) {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.item {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  background: rgba(53, 151, 64, 0.08);
  border-radius: 10px;
  transition: background 0.5s ease-in-out;
  @media (max-width: 920px) {
    gap: 15px;
  }
  @media (max-width: 850px) {
    padding: 15px;
  }
  @media (max-width: 768px) {
    gap: 10px;
  }
  @media (max-width: 640px) {
    height: 120px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 640px) {
    height: 100px;
  }
  @media (max-width: 450px) {
    height: 80px;
  }
  p {
    color: $black;
    text-align: center;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    height: 42px;
    overflow: hidden;
    @media (max-width: 920px) {
      font-size: 14px;
      height: 20px;
    }
    @media (max-width: 768px) {
      font-size: 14px;
    }
    @media (max-width: 600px) {
      font-size: 13px;
    }
    @media (max-width: 640px) {
      height: auto;
      font-size: 16px;
    }
    @media (max-width: 640px) {
      font-size: 14px;
    }
  }
  &:hover {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
    background: $green;
    p {
      color: $white;
    }
    .img {
      transform: scale(1.1);
    }
  }
  &:active {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
    background: $green;
    p {
      color: $white;
    }
  }
}
.img {
  transition: transform 0.3s ease-in-out;

  overflow: hidden;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $white;
  @media (max-width: 1024px) {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 820px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 600px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 640px) {
    display: none;
  }
  img {
    
    overflow: hidden;
    border-radius: 50%;
    height: 100%;
    width: auto;
    object-fit: cover;
  }
}
