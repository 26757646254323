@import "../variables";

.newProductCard {
  cursor: pointer;

  position: relative;
  width: 100%;
  /*  aspect-ratio: 310 / 530; */

  border-radius: 10px;
  border: 1px solid #8fc23d;
  overflow: hidden;
  /* @media (max-width: 400px) {
    aspect-ratio: 310 / 560;
  } */
  &__wrapper {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    @media (max-width: 900px) {
      gap: 5px !important;
    }
    @media (max-width: 767px) {
      gap: 0px !important;
    }
    @media (min-width: 1024px) {
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}
.img {
  width: 100% !important;
  aspect-ratio: 310 / 325;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }
}
.descriptions {
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: fit-content;
  align-items: center;
  gap: 12%;
  padding: 4% 3% 0% 3%;
  &::before {
    content: "";
    height: fit-content;
    display: block;
  }

  @media (min-width: 1601px) {
    gap: 15px;
  }

  @media (min-width: 1301px) {
    gap: 7px;
  }
  @media (max-width: 1300px) {
    gap: 7px;
  }
  @media (max-width: 820px) {
    gap: 5px;
  }
  @media (max-width: 768px) {
    gap: 4px;
    padding: 1% 3% 3% 3%;
  }
}
.feedback {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 9px;
  @media (max-width: 768px) {
    gap: 5px;
  }
}
.stars {
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
  }
}
.countFeedback {
  p {
    color: #ff9b00;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    color: #ff9b00;
    font-weight: 700;
    line-height: 21px; /* 162.712% */
  }
}
.title {
  width: 100%;
  text-align: start;
  height: 42px;
  overflow: hidden;
  color: #000;
  font-family: "Noto Sans";
  font-size: clamp(0.9375rem, 0.885rem + 0.2vw, 1.125rem);
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 50px;
  } */
}
.moreTitle {
  height: 82px !important;
  width: 100%;
  text-align: start;
  overflow: hidden;
  color: #000;
  font-family: "Noto Sans";
  font-size: clamp(0.9375rem, 0.885rem + 0.2vw, 1.125rem);
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical;

  @media (max-width: 1024px) {
    height: 63px !important;
    -webkit-line-clamp: 3 !important;
  }
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.price {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10%;
  height: 100%;
  min-height: 51px;
  flex: 1 1 0 !important;
  flex-direction: column-reverse;

  @media (min-width: 1301px) {
    gap: 10px;
    min-height: 66px;
  }
  @media (max-width: 1300px) {
    gap: 10px;
  }
  @media (max-width: 1024px) {
    gap: 10px;
    min-height: 61px;
  }

  @media (max-width: 820px) {
    gap: 7px;
    min-height: 61px;
  }
  @media (max-width: 768px) {
    min-height: 51px;
  }
  @media (max-width: 767px) {
    gap: 0px;
  }
}
.priceTotal,
.priceTotalDiscounted {
  color: #000;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 139.023%; /* 37.536px */
  span {
    margin-left: 3px;
  }
}
.priceOriginal {
  color: #c4c4c4;
  font-family: "Noto Sans";
  font-size: clamp(0.8125rem, 0.76rem + 0.2vw, 1rem);
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
  text-decoration: line-through;
}
.card {
  position: absolute;
  z-index: 1;
  height: 40px;

  bottom: 1.5%;
  right: 4.5%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 100%;
  }

  @media (max-width: 767px) {
    svg {
      height: 30px;
      width: 30px;
    }
  }
}

.widget {
  position: absolute;
  top: 2%;
  left: 1px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  div {
    border-radius: 50%;
    overflow: hidden;
    width: 50px;
    height: 50px;
    /*   aspect-ratio: 1/1; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      width: 37px;
      height: 37px;
    }
  }
  &__new {
    margin: 2px;
    span {
      color: #fff;
      text-align: center;
      font-family: "Noto Sans";
      font-size: clamp(0.75rem, 0.715rem + 0.1333vw, 0.875rem);
      font-style: normal;
      font-weight: 600;
      line-height: 139.023%; /* 19.463px */
    }
    background-color: #74ccd8;
  }
  &__hit {
    margin: 2px;
    background-color: #8fc23d;
    span {
      color: var(--White, #fff);
      text-align: center;
      font-family: "Noto Sans";
      font-size: clamp(0.75rem, 0.715rem + 0.1333vw, 0.875rem);
      font-style: normal;
      font-weight: 600;
      line-height: 139.023%; /* 19.463px */
    }
  }
  &__discount {
    span {
      position: absolute;
      z-index: 1;
      color: var(--Dark, #090f24);
      text-align: center;
      font-family: "Noto Sans";
      font-size: clamp(0.75rem, 0.715rem + 0.1333vw, 0.875rem);
      font-style: normal;
      font-weight: 600;
      line-height: 139.023%; /* 19.463px */
    }
  }
}
.favorites {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 2%;
  right: 4.5%;
  background-color: #f4f5f9;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  &:hover {
    box-shadow: 0px 0.5px 4px 0px rgba(0, 0, 0, 0.32);
  }
  svg {
    height: 100%;
  }
  @media (max-width: 767px) {
    width: 40px;
    height: 40px;
  }
}
.priceOriginalBlock {
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.discountedSum {
  width: fit-content;
  white-space: nowrap;
  color: #fff;
  font-family: "Noto Sans";
  font-size: clamp(0.875rem, 0.805rem + 0.2667vw, 1.125rem);
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 236.364% */
  background-color: #8fc23d !important;
  border-radius: 5px;
  padding: 0 2%;
}
.noDiscount {
  min-height: 26px !important;
  @media (max-width: 1024px) {
    min-height: 40px !important;
  }
  @media (max-width: 767px) {
    min-height: 30px !important;
  }
}
