@import "./variables";
@import "libs/nullstyle";

// font-family: 'Noto Sans', sans-serif;
// font-family: "Rubik", sans-serif;
// font-family: "Ruda", sans-serif;
// font-family: 'Pacifico', cursive;
// font-family: 'Switzer', sans-serif;

@font-face {
  font-family: "Switzer";
  src: url("../font/Switzer-Bold.ttf") format("truetype");
}

body {
  p {
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    color: $black;
  }
}

.container {
  @media (min-width: 1600px) {
    margin: 0 auto;
    max-width: 1600px;
  }
  @media (max-width: 1599px) {
    width: 100%;
    margin: 0 auto;
    padding: 0px 40px;
  }
  @media (max-width: 1224px) {
    margin: 0 auto;
    padding: 0px 35px;
  }
  @media (max-width: 1024px) {
    margin: 0 auto;
    padding: 0px 30x;
  }
  @media (max-width: 768px) {
    margin: 0 auto;
    padding: 0px 30px;
  }
  @media (max-width: 767px) {
    margin: 0 auto;
    padding: 0px 8px;
  }
  @media (max-width: 450px) {
    margin: 0 auto;
    padding: 0px 10px;
  }
  @media (max-width: 300px) {
    margin: 0 auto;
    padding: 0px 7px;
  }
}
