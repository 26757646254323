.phone-widget {
  -webkit-box-shadow: 0.5px 0 18px 5px #3cc23e;
  -moz-box-shadow: 0.5px 0 18px 5px #3cc23e;
  box-shadow: 0.5px 0 18px 5px #3cc23e;

  background-color: #8fc23d;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    -webkit-box-shadow: 0.5px 0 18px 5px #3cc23e;
    -moz-box-shadow: 0.5px 0 18px 5px #3cc23e;
    box-shadow: 0.5px 0 18px 5px #3cc23e;
    background-color: #6b932c;
  }

  lord-icon {
    width: 100%;
    height: 100%;
  }
  
  @media (min-width: 1025px) {
    width: 40px;
    height: 40px;
    lord-icon {
      width: 30px;
      height: 30px;
    }
  }
  @media (max-width: 1024px) {
    width: 40px;
    height: 40px;
    lord-icon {
      width: 25px;
      height: 25px;
    }
  }
}
