@import "../variables";

.productCardDetails {
  margin-bottom: 40px;
}
.top {
  margin-bottom: 30px;
  @media (max-width: 320px) {
    margin-bottom: 10px;
  }
}
.title {
  color: $black;
  font-family: "Rubik", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 139.023%; /* 38.926px */
  margin-bottom: 30px;
  margin-top: 20px;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.twoPart {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 1224px) {
    align-items: center;
  }
  @media (max-width: 940px) {
    flex-direction: column;
    align-items: center;
  }
}
.carousel {
  position: relative;
  width: fit-content;
  &__leftBtn {
    top: 50%;
    left: 12px;
    height: 30px;
    width: auto;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 2;
    cursor: pointer;
  }
  &__rightBtn {
    top: 50%;
    right: -10px;
    height: 30px;
    width: auto;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 2;
    cursor: pointer;
  }
}
.embla {
  width: 500px;
  border-radius: 25px;
  border: 2px solid $green;
  overflow: hidden;
  display: flex;
  justify-content: center;
  @media (max-width: 660px) {
    width: 290px;
  }
}
.wrapper {
  width: 100%;
  display: flex;
}
.slide {
  cursor: pointer;
  height: 327px;
  overflow: hidden;
  flex: 0 0 100%;
  min-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px;
  img {
    height: 100%;
  }
  @media (max-width: 660px) {
    padding: 15px;
  }
}
.widget {
  position: absolute;
  left: -15px;
  top: 20px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  p {
    border-radius: 10px;
    padding: 7px 10px;
    color: $white;
    text-align: center;
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 139.023%; /* 19.463px */
  }
  &__new {
    background-color: #32b9f3;
  }
  &__hit {
    background-color: $green;
  }
  &__discount {
    background-color: $red;
  }
}
.info {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media (max-width: 1224px) {
    gap: 10px;
  }
  @media (max-width: 768px) {
    width: 70%;
    align-content: center;
  }
  @media (max-width: 660px) {
    width: 100%;
  }

  &__header {
    padding: 20px;
    border-radius: 13px;
    border: 1px solid $green;
    background: $white;
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    @media (max-width: 1224px) {
      flex-direction: column;
      gap: 20px;
    }
    @media (max-width: 768px) {
      align-content: center;
      gap: 10px;
    }
  }
  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: 768px) {
      align-items: center;
      gap: 10px;
    }
  }
  &__delivery {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    p {
      color: #686877;
      font-family: "Noto Sans", sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 139.023%; /* 20.853px */
      @media (max-width: 1224px) {
        font-size: 14px;
      }
    }
    svg {
      height: 16px;
    }
  }
  &__article {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    span {
      color: #686877;
      font-family: "Noto Sans", sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 139.023%; /* 20.853px */
      @media (max-width: 1224px) {
        font-size: 14px;
      }
    }
    p {
      color: #686877;
      font-family: "Noto Sans", sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 139.023%; /* 20.853px */
      @media (max-width: 1224px) {
        font-size: 14px;
      }
    }
  }
  &__availability {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    &__yes {
      color: $green;
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 139.023%; /* 19.463px */
    }
    &__no {
      color: $ashen;
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 139.023%; /* 19.463px */
    }
  }
  &__feedback {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    p {
      color: $ashen;
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 139.023%;
    }
    svg {
      height: 20px;
      width: 20px;
    }
  }

  &__main {
    overflow: hidden;
    padding: 20px;
    border-radius: 13px;
    border: 1px solid $green;
    background: $white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__items {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      @media (max-width: 1224px) {
        gap: 10px;
        flex-wrap: wrap;
      }

      @media (max-width: 940px) {
        justify-content: center;
        gap: 10px;
      }
    }
  }
}
.price {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.totalPrice,
.priceTotalDiscounted {
  color: $black;
  font-family: "Noto Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 36.146px */
  span {
    margin-left: 5px;
    font-size: 18px;
  }
}
.priceOriginal {
  color: $ashen;
  font-family: "Noto Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 25.024px */
  text-decoration: line-through;
}
.unitsLeft {
  p {
    color: $red;
    text-align: right;
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 155.023%; /* 18.603px */
  }
  span {
    font-weight: 700;
    margin: 0px 5px;
  }
}
.count {
  width: 140px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid $gray;
  padding: 5px;
  &__btn {
    cursor: pointer;
    background-color: $gray;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 15px;
      height: 15px;
    }
  }
  &__number {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      height: 100%;
      width: 50px;
      padding: 5px;
      color: inherit;
      border-radius: 10px;
      text-align: center;
      background: var(--Invisible-green, rgba(53, 151, 64, 0.08));
      color: $black;
      font-family: "Noto Sans", sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 139.023%; /* 20.853px */
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
}
.addToCart {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--green_brand, #8fc23d);
  padding: 11px 33px;
  @media (max-width: 1224px) {
    padding: 7px 15px;
  }
  p {
    color: $white;
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 139.023%;
  }
  &:hover {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.32);
  }
}
.disabled {
  background: $ashen;
}
.favorites {
  z-index: 1;
  cursor: pointer;
  border: 1px solid $gray;
  border-radius: 50%;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  svg {
    height: 30px;
  }
  &:hover {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.32);
  }
}
.lab-tabs {
  width: 100%;
}
.tab-container {
  div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.tab-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 15px 15px 0px 0px;
  padding: 10px 0;
  cursor: pointer;
  border: 1px solid $green;
  color: $black;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 139.023%;
  font-family: "Noto Sans", sans-serif;
  input[type="radio"] {
    display: none;
  }
  &.active {
    font-weight: bold;
    background-color: $green;
    color: $white;
  }
  @media (max-width: 768px) {
    font-size: 13px;
  }
  @media (max-width: 660px) {
    padding: 10px 3px;
  }
}
.tab-content {
  display: none;
  border-radius: 0px 0px 15px 15px;
  background: $gray;
  height: 587px;
  padding: 20px 20px 20px 20px;
  transition: transform 0.3s ease;

  &.active {
    display: block;
  }
}
.productDescription {
  position: relative;
  padding: 0 5px;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  line-height: 22px;
  height: 520px;
  overflow: hidden;
  h1 {
    font-size: 16px !important;
  }
  ul {
    display: block !important;
  }
  &::after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 80px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), $gray 100%);
    pointer-events: none;
  }
}
.showMoreActive {
  height: auto;
  transition: transform 0.3s ease;

  .productDescription {
    transition: transform 0.3s ease;

    &::after {
      display: none;
    }
  }
}
.specifications {
  padding: 30px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: transform 0.3s ease;
  height: 495px;
  overflow: hidden;
  @media (max-width: 660px) {
    padding: 0px;
  }
  &::after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 80px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), $gray 100%);
    pointer-events: none;
  }
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-bottom: 1px solid $ashen;
    gap: 50px;
    @media (max-width: 660px) {
      flex-direction: column;
      gap: 5px;
      align-items: start;
      width: 100%;
    }
  }
  &__title {
    color: $black;
    text-transform: uppercase;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 155.023%;
    margin-bottom: 3px;
  }
  &__description {
    width: 70%;
    display: flex;
    flex-direction: column;

    p {
      text-align: end;
      color: var(--Text, #686877);
      font-family: "Noto Sans", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 155.023%;
    }
    @media (max-width: 660px) {
      align-items: start;
      width: 100%;

      p {
        width: 100% !important;
        text-align: start;
      }
    }
  }
}
.showMoreActiveSpecifications {
  height: auto;
  transition: transform 0.3s ease;

  .specifications {
    transition: transform 0.3s ease;

    &::after {
      display: none;
    }
  }
}
.reviews {
  padding: 40px 0 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;

  &__embla {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
  }
  &__wrapper {
    width: 100%;
    display: flex;
    gap: 25px;
    justify-content: flex-start;
  }
  &__slide {
    cursor: pointer;
    overflow: hidden;
    flex: 0 0 32%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    background: $white;
    padding: 40px;
    gap: 10px;
    height: 320px;
    @media (max-width: 1224px) {
      flex: 0 0 40%;
      padding: 30px;
      height: 300px;
    }
    @media (max-width: 768px) {
      flex: 0 0 60%;
      padding: 20px;
    }
    @media (max-width: 550px) {
      flex: 0 0 80%;
      gap: 0px;
    }
    @media (max-width: 450px) {
      flex: 0 0 100%;
    }
  }
  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    &__title {
      height: 26px;
      color: $black;
      font-family: "Rubik", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 129.023%; /* 30.585px */
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      @media (max-width: 1224px) {
        font-size: 18px;
      }
      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 139.023%; /* 30.585px */
      }
    }
    svg {
      height: 20px;
      width: 20px;
    }
  }
  &__description {
    width: 100%;
    height: 105px;
    margin-bottom: 15px;
    text-align: start;
    color: #686877;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 155.023%; /* 26.354px */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    @media (max-width: 768px) {
      font-size: 13px;
    }
  }
  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    &__user {
      color: $black !important;
      font-family: "Noto Sans", sans-serif;
      font-size: 15px !important;
      font-style: normal;
      font-weight: 500 !important;
      line-height: 142%; /* 21.3px */
      height: 21px;
      width: 100% !important;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      @media (max-width: 768px) {
        font-size: 14px !important;
      }
    }
    &__date {
      width: 150px !important;
      color: $ashen;
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 139.023%; /* 19.463px */

      @media (max-width: 768px) {
        font-size: 12px;
        width: 100px !important;
      }
    }
  }
}
.reviewsEmpty {
  text-align: center;
  color: $ashen;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.showMore {
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transition: transform 0.3s ease;
  p {
    cursor: pointer;
    color: var(--Gray-3, #b7b8c5);
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  svg {
    cursor: pointer;
  }
}
.addReview {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  border-radius: 60px;
  gap: 10px;
  background-color: $green;
  p {
    color: $white;
    font-family: "Noto Sans", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  @media (max-width: 660px) {
    padding: 10px 20px;
  }
  svg {
    height: 20px;
  }
  &:hover {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.32);
  }
}
.modal-open {
  overflow: hidden;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(63, 72, 103, 0.9); /* Полупрозрачный черный цвет */
  z-index: 7; /* Выше других элементов */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background: var(--Gray-1-light, #f4f5f9);
  box-shadow: 0 24px 24px rgba(0, 0, 0, 0.2);
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 8;
  gap: 15px;
  @media (max-width: 768px) {
    padding: 15px;
    width: 70%;
  }
  @media (max-width: 520px) {
    padding: 15px;
    width: 90%;
  }
}
.modalForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.modalTitle {
  color: $black;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 139.023%; /* 30.585px */
  @media (max-width: 660px) {
    font-size: 14px;
    font-weight: 700;
  }
}
.modalField {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  input,
  textarea {
    width: 100%;
    padding: 10px 20px;
    border-radius: 15px;
    border: 1px solid var(--Gray-3, #b7b8c5);
    background: $white;
    resize: none;
    color: $black;
    font-family: "Noto Sans", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 155.023%; /* 23.253px */
    @media (max-width: 768px) {
      font-size: 14px;
    }
    @media (max-width: 520px) {
      padding: 5px 10px;
      border-radius: 7px;
    }
    &::placeholder {
      color: $ashen;
      font-family: "Noto Sans", sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 155.023%; /* 23.253px */
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    &:hover {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.32);
    }
  }
  textarea {
    height: 150px;
  }
}
.modalEstimation {
  display: flex;
  align-items: center;
  gap: 15px;
  @media (max-width: 660px) {
    flex-direction: column;
    justify-content: center;
  }
  svg {
    height: 35px;
    @media (max-width: 660px) {
      height: 20px;
      width: 20px;
    }
  }
  p {
    color: $black;
    font-family: "Noto Sans", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 142%; /* 21.3px */
  }
}
.modalSend {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 28px;
  border-radius: 60px;
  background-color: $green;

  color: $white;
  font-family: "Noto Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 660px) {
    padding: 10px 20px;
  }
  &:hover {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.32);
  }
}
.hidden {
  display: none !important;
}

.modalCarouselOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  z-index: 7;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalCarouselHeader {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  z-index: 9;
  background-color: #0909094e;
}
.modalCarouselCount {
  cursor: pointer;
  font-family: "Noto Sans", sans-serif;
  color: #fff;
  font-size: 13px;
  line-height: 44px;
  opacity: 0.75;
  span {
    margin: 0 2px;
  }
}
.modalCarouselTools {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  svg {
    cursor: pointer;
    height: 20px;
    margin: 5px;
  }
}
.modalCaruoselArrowRight,
.modalCaruoselArrowLeft {
  top: 50%;
  height: 30px;
  width: auto;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding: 5px;
  background-color: #0909094e;
  cursor: pointer;
}
.modalCaruoselArrowRight {
  right: -10px;
}
.modalCaruoselArrowLeft {
  left: 20px;
}
.modalCarouselContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8;
  overflow: hidden;
}
.modalCarouselWrapper {
  height: 100%;
  display: flex;
}
.modalCarouselSlider {
  background-color: #0909094e;
  height: 85vh;
  overflow: hidden;
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  backface-visibility: hidden;
  will-change: transform;
  &.zoomed {
    height: 100vh;
    overflow: auto;
    img {
      transform: scale(1.5);
      transform-origin: top center;
    }

    @media (max-width: 1040px) {
      height: 80vh;
    }
  }

  img {
    object-fit: cover;
    overflow: hidden;
    aspect-ratio: 1/1;
    height: 100%;
    background-color: #fff;
    cursor: zoom-in;
    transform-origin: center center;
    transition: transform 0.3s ease;
    @media (max-width: 1040px) {
      width: 100vw;
    }
  }

  @media (max-width: 1040px) {
    height: fit-content;
  }
}
.activeSlide {
  z-index: 9;
  background-color: #090909;
}
