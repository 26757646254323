@import "../variables";

.login {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &__wrapper {
    position: relative;
    width: fit-content;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 50px;
    box-shadow: 0px 0.5px 4px 0px rgba(0, 0, 0, 0.32);
    div {
      display: flex;
      flex-direction: column;
      gap: 4px;
      span {
        color: #000;
        font-family: "Noto Sans";
        font-size: clamp(0.9375rem, 0.885rem + 0.2vw, 1.125rem);
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      input {
        border: 1px solid $green;
        padding: 10px 14px;
      }
    }
    button {
      padding: 10px;
      color: #fff;
      font-family: "Noto Sans";
      font-size: clamp(0.9375rem, 0.885rem + 0.2vw, 1.125rem);
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      background-color: $green;
      &:hover {
        box-shadow: 0px 0.5px 4px 0px rgba(0, 0, 0, 0.32);
      }
    }
    p {
        display: none;
      position: absolute;
      top: 10px;
      right: 10px;
      color: red;
      font-family: "Noto Sans";
      font-size: clamp(0.9375rem, 0.885rem + 0.2vw, 1.125rem);
      font-style: normal;
      font-weight: 600;
      line-height: 12px;
    }
  }
}
.disabled {
  background-color: #a29f9f !important;
}
.hidden {
  display: none !important;
}
