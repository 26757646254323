@import "../variables";

.footer {
  border-top: 1px solid $green;
  margin-top: auto;
  box-shadow: 0px -2px 2px 0px rgba(51, 196, 223, 0.2);
}
.container {
  @media (min-width: 1600px) {
    margin: 0 auto;
    max-width: 1600px;

    padding: 0px 80px;
  }
  @media (max-width: 1599px) {
    width: 100%;
    margin: 0 auto;
    padding: 0px 80px;
  }
  @media (max-width: 1224px) {
    margin: 0 auto;
    padding: 0px 80px;
  }
  @media (max-width: 1088px) {
    margin: 0 auto;
    padding: 0px ;
  }
  
}
.main {
  /*  padding: 20px 0 50px 0; */
  position: relative;
  overflow: hidden;
  &__wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 1088px) {
      flex-direction: column !important;
      align-items: center;
    }
  }
  /*  @media (max-width: 1024px) {
    padding: 15px 0 30px 0;
  }
  @media (max-width: 320px) {
    padding: 15px 0 20px 0;
  } */
}
.title {
  color: $black;
  font-family: "Noto Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 139.023%;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    font-size: 17px;
    margin-bottom: 15px;
  }
  @media (max-width: 700px) {
    text-align: center;
    margin-bottom: 10px;
  }
  @media (max-width: 450px) {
    margin-bottom: 5px;
    gap: 15px;
  }
}
.contacts {
  width: 33%;
  padding: 20px 50px 100px 10px;
  @media (max-width: 1024px) {
    padding: 20px 30px 100px 10px;
  }
  @media (max-width: 1088px) {
    border-top: 1px solid $green;
    border-bottom: 1px solid $green;
    padding: 10px 25px 25px 25px;
    order: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 600px) {
    align-items: flex-start;
  }
  &__items {
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media (max-width: 768px) {
      gap: 10px;
    }
    @media (max-width: 600px) {
      align-items: flex-start;
    }
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      a,
      p {
        font-family: "Noto Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: $black;
        &:hover {
          color: $green;
        }
      }
      svg {
        width: 20px;
        height: 20px;
        @media (max-width: 1024px) {
          width: 20px;
          height: 20px;
        }
      }
      @media (max-width: 700px) {
        justify-content: center;
      }
    }
  }
}
.menu {
  width: 33%;
  padding: 20px 50px 100px 50px;
  border-right: 1px solid $green;
  border-left: 1px solid $green;
  @media (max-width: 1024px) {
    padding: 20px 30px 100px 30px;
  }
  @media (max-width: 1086px) {
    border-right: none;
    border-left: none;
    padding: 10px 25px 25px 25px;
    order: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 600px) {
    align-items: flex-start;
  }
  &__items {
    display: flex;
    flex-direction: row;
    gap: 40px;

    nav {
      @media (max-width: 1088px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      @media (max-width: 600px) {
        align-items: flex-start;
      }
      ul {
        display: flex;
        width: fit-content;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        @media (max-width: 1088px) {
          align-items: center;
        }
        @media (max-width: 600px) {
          align-items: flex-start;
        }
        li {
          a {
            font-family: "Noto Sans", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: $black;
            @media (max-width: 1088px) {
              align-items: center;
            }
            &:hover {
              color: $green;
            }
          }
        }
      }
    }
    @media (max-width: 1120px) {
      gap: 5px;
      flex-direction: column;
    }
  }
}
.social {
  width: 33%;
  padding: 20px 50px 100px 50px;
  @media (max-width: 1024px) {
    padding: 20px 30px 100px 30px;
  }
  @media (max-width: 1088px) {
    padding: 10px 25px 25px 25px;
    order: 3;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 600px) {
    align-items: flex-start;
  }
  &__items {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 10px;
    svg {
      height: 40px;
      width: 40px;
      @media (max-width: 1024px) {
        width: 25px;
        height: 25px;
      }
    }
    @media (max-width: 700px) {
      justify-content: center;
    }
  }
}
.copyright {
  background-color: #8fc23d;
  &__wrapper {
    display: flex;
    justify-content: center;
    p {
      padding: 5px 0;
      color: $white;
      text-align: center;
      font-family: "Noto Sans", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media (max-width: 700px) {
        padding: 3px 0;
        font-size: 11px !important;
      }
    }
  }
}
