@import "../variables";

.mainBannerChapter {
  width: 100%;
  position: relative;
}
.items {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.5%;
  height: 100%;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
 
}

.item {
  position: relative;
  width: 100%;
  max-height: 305px;
  min-height: 125px;
  height: 100% !important;

  overflow: hidden;
  border-radius: 10px;
  background-color: $white;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.descriptoins {
  padding: 10px;
  position: relative;
  z-index: 1;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 1 0 !important;
  gap: 10px;
  button {
    white-space: nowrap;
    display: flex;
    border: none;
    padding: 10px;
    position: relative;
    padding: 14px 30px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 53px;
    background: #fafafa;
    @media (max-width: 1024px) {
      padding: 10px 15px;
    }
    &:hover {
      box-shadow: 0px 0.5px 4px 0px rgba(0, 0, 0, 0.32);
    }
    span {
      color: $green;
      font-family: "Noto Sans";
      font-size: clamp(0.75rem, 0.5225rem + 0.8667vw, 1.5625rem);
      text-transform: capitalize;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
.image {
  position: absolute;
  width: 100%;
  height: 100% !important;
  overflow: hidden;
  img {
    width: 100% !important;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    filter: brightness(90%);
  }
}
.title {
  max-width: 325px;
  height: 200px;
  color: #fff;
  font-family: "Switzer" sans-serif;
  font-size: clamp(1.5625rem, 1.125rem + 1.6667vw, 3.125rem);
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  @media (max-width: 1024px) {
    height: 120px;
    max-width: 90%;
  }
  @media (max-width: 768px) {
    height: 85px;
    max-width: 350px;
  }
  @media (max-width: 767px) {
    height: 68px;
    max-width: 250px;
  }
}
.items :nth-child(2) .descriptoins button {
  span {
    color: #fed42f !important;
  }
  svg path {
    stroke: #fed42f !important;
  }
}

