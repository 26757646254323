@import "../variables";

.bannerAboutUs {
  overflow: hidden;
  background-color: $white;
}
.twoPart {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
}
.first {
  margin-left: 20px;
  z-index: 0;
  width: 70%;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: $white;
  @media (max-width: 1024px) {
    padding: 50px 0;
  }
  @media (max-width: 1144px) {
    padding: 40px 0;
    width: 100% !important;
  }
  @media (max-width: 600px) {
    gap: 10px;
    margin-left: 10px;
    padding: 25px 0;
  }
}

.title {
  width: 100% !important;
  color: $black;
  font-family: "Rubik", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 139.023%; /* 55.609px */
  @media (max-width: 1024px) {
    font-size: 28px;
  }
}
.description {
  width: 80%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
  }
  p {
    color: #686877;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 174.023%;

    span {
      color: $green;
      font-family: "Noto Sans", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 174.023%; /* 34.805px */
    }
    @media (max-width: 1024px) {
      font-size: 13px;
      span {
        font-size: 16px;
      }
    }
    @media (max-width: 768px) {
      font-size: 13px;
      width: 100% !important;
      span {
        font-size: 14px;
      }
    }
  }
  @media (max-width: 768px) {
    width: 100% !important;
  }
}
.btn {
  max-width: 250px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  p {
    color: $green;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 139.023%;
  }
}
.second {
  display: flex;
  align-items: center;
  width: 40%;
  right: 0;
  height: 100%;
  position: absolute;

  z-index: 1;
  &__background {
    right: 0;
    position: absolute;
    height: 100% !important;
    width: auto;
  }
  @media (max-width: 1144px) {
    display: none;
    position: static;
    width: 100%;
    justify-content: center;
  }
}
.contant {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  @media (max-width: 600px) {
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
}
.img {
  z-index: 2;
  margin-left: 20%;
  overflow: hidden;
  width: 300px;
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: $white;
  box-shadow: 0px 4px 12px 0px rgba(26, 25, 25, 0.25);
  img {
    height: 100%;
    width: auto;
    object-fit: cover;
  }
  @media (max-width: 1500px) {
    margin-left: 10%;
  }
  @media (max-width: 1330px) {
    margin-left: 0;
  }
  @media (max-width: 1024px) {
    width: 250px;
    height: 250px;
  }
  @media (max-width: 600px) {
    left: 0;
    right: 0;
  }
}
.slogan {
  margin-right: 50px;
  color: $white;
  z-index: 2;
  text-align: right;
  font-family: "Pacifico", cursive;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 31px */
  @media (max-width: 1125px) {
    margin-right: 20px;
    font-size: 16px;
  }
}
.logoMb {
  @media (min-width: 1141px) {
    display: none;
  }
  height: 110px;
  object-fit: cover;
}
