@import "../variables";

.secondStep {
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 940px) {
    flex-direction: column;
    gap: 20px;
    align-items: center;
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
.delivery {
  width: 40%;
  padding: 20px 30px;
  border-radius: 12px;
  height: fit-content;
  @media (max-width: 940px) {
    width: fit-content;
    padding: 0;
  }

  @media (max-width: 370px) {
    width: 100%;
  }
  &__items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: 400px) {
      align-items: center;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: fit-content;
    @media (max-width: 940px) {
      gap: 10px;
    }
    @media (max-width: 768px) {
      gap: 5px;
    }
  }
}
.deliveryTitle {
  color: $black;
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;
  margin-bottom: 30px;
  @media (max-width: 700px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
  @media (max-width: 600px) {
    font-size: 16px;
    margin-bottom: 10px;
  }
  @media (max-width: 400px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
  @media (max-width: 370px) {
    font-size: 16px;
    margin-bottom: 2px;
  }
}
.deliveryHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  @media (max-width: 940px) {
    justify-content: center;
  }
  p {
    color: $black;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.36px;

    @media (max-width: 600px) {
      font-size: 15px;
    }
    @media (max-width: 400px) {
      font-size: 14px;
    }
    @media (max-width: 350px) {
      font-size: 13px;
    }
  }
}
.deliveryContant {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.deliveryHeaderItem {
  width: 300px;
  position: relative;
  padding: 10px 20px;
  background-color: $white;
  border-radius: 12px;
  border: 1px solid $gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 4;
  @media (max-width: 600px) {
    padding: 7px 15px;
  }
  @media (max-width: 400px) {
    width: 250px;
  }
  &:focus {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
  input {
    cursor: pointer;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 7px;
    left: 7px;
  }
  div {
    svg {
      cursor: pointer;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 7px;
      right: 7px;
    }
    &:hover {
      .deliveryItemNote {
        display: block;
      }
    }
    @media (max-width: 1007px) {
      display: none !important;
    }
  }
}
.deliveryItemTitle {
  color: $black;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 24px; /* 155.556% */
  }
  @media (max-width: 400px) {
    line-height: 20px; /* 155.556% */
    font-size: 13px;
  }
  @media (max-width: 350px) {
    font-size: 12px;
    line-height: 16px; /* 155.556% */
  }
}
.deliveryItemPrice {
  color: $black;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 00;
  line-height: 24px; /* 155.556% */
  letter-spacing: -0.36px;
  span {
    font-size: 12px;
    font-weight: 500;
  }
}
.deliveryItemNote {
  display: none;
  position: absolute;
  width: 300px;
  top: 20px;
  right: -310px;
  z-index: 5 !important;
  color: $ashen;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 155.556% */
  letter-spacing: -0.36px;
  padding: 5px;
  background-color: $white;
  border: 1px solid $green;
  border-radius: 7px;
  @media (max-width: 1007px) {
    display: none !important;
  }
}
.deliveryItemDropDown {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  padding: 10px;
  background-color: #fefafa;
  width: 280px;
  p {
   
    color: $ashen;
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 155.556% */
    letter-spacing: -0.36px;
  }
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media (max-width: 400px) {
    width: 230px;
    padding: 7px 10px;
  }
  @media (max-width: 350px) {
    font-size: 12px;
    line-height: 16px; /* 155.556% */
  }
}
.data {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 30px;
    border-radius: 17px;
    border: 1px solid $gray;
    background: $white;
    box-shadow: 0px 4px 12px 0px rgba(207, 207, 207, 0.25);
    @media (max-width: 940px) {
      width: 400px;
      align-items: center;
    }
    @media (max-width: 450px) {
      width: fit-content;
    }
  }
}
.personalInfoTitle {
  color: $black;
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;
  margin-bottom: 10px;
  @media (max-width: 700px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 16px;
  }
  @media (max-width: 400px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
  @media (max-width: 350px) {
    font-size: 16px;
    margin-bottom: 2px;
  }
}
.personalInfo {
  &__items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    p {
      color: $ashen;
      font-family: "Noto Sans", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 178.187%; /* 24.946px */
    }
    input {
      padding: 14px 14px;
      border-radius: 4px;
      border: 1px solid $gray;
      color: $black;
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 178.187%;
      @media (max-width: 500px) {
        padding: 7px 10px;
        font-size: 12px;
      }
      &::placeholder {
        color: $ashen;
        font-family: "Noto Sans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 178.187%; /* 24.946px */
        @media (max-width: 500px) {
          font-size: 12px;
        }
      }
      &:hover {
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
      }
      &:focus {
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
      }
    }
  }
}
.adress {
  max-width: 100%;

  &__items {
    max-width: 100%;

    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__item {
    max-width: 100%;

    display: flex;
    flex-direction: column;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    p {
      color: $ashen;
      font-family: "Noto Sans", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 178.187%; /* 24.946px */
    }
    input {
      padding: 14px 14px;
      border-radius: 4px;
      border: 1px solid $gray;
      color: $black;
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 178.187%;
      @media (max-width: 500px) {
        padding: 7px 10px;
        font-size: 12px;
      }
      &::placeholder {
        color: $ashen;
        font-family: "Noto Sans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 178.187%; /* 24.946px */
        @media (max-width: 500px) {
          font-size: 12px;
        }
      }
      &:hover {
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
      }
      &:focus {
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
      }
    }
    textarea {
      padding: 14px 14px;
      border-radius: 4px;
      height: 150px;
      border: 1px solid $gray;
      color: $black;
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 178.187%;
      resize: none;
      @media (max-width: 500px) {
        padding: 7px 10px;
        font-size: 12px;
      }
      &::placeholder {
        color: $ashen;
        font-family: "Noto Sans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 178.187%; /* 24.946px */
        @media (max-width: 500px) {
          font-size: 12px;
        }
      }
      &:hover {
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
      }
      &:focus {
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
      }
    }
    textarea::-webkit-scrollbar {
      margin: 5px;
      width: 6px; /* Ширина скроллбара */
    }
    textarea::-webkit-scrollbar-thumb {
      background-color: $gray; /* Цвет бара прокрутки */
      border-radius: 5px; /* Скругление углов бара */
    }
  }
}
.adressTitle {
  color: $black;
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;
  margin-bottom: 10px;
  @media (max-width: 700px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 16px;
  }
  @media (max-width: 400px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
  @media (max-width: 350px) {
    font-size: 16px;
    margin-bottom: 2px;
  }
}
.adressSelectCoutry {
  max-width: 100% !important;
  &__main {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 2;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    p {
      color: $ashen;
      font-family: "Noto Sans", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 178.187%; /* 24.946px */
    }
    input {
      padding: 14px 14px;
      border-radius: 4px;
      border: 1px solid $gray;
      color: $black;
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 178.187%;
      @media (max-width: 500px) {
        padding: 7px 10px;
        font-size: 12px;
      }
      &::placeholder {
        color: $ashen;
        font-family: "Noto Sans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 178.187%; /* 24.946px */
        @media (max-width: 500px) {
          font-size: 12px;
        }
      }
      &:hover {
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
      }
      &:focus {
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
      }
    }
  }
  &__dropDown {
    max-width: 100% !important;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: $white;
    height: 200px;
    width: fit-content;
    border-top: none;
    transition: all 0.1s ease;
    overflow: auto;
    z-index: 1;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
    &::-webkit-scrollbar {
      width: 6px;
      height: calc(100% - 50px);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #8fc23d;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: none;
    }
    @media (max-width: 500px) {
      height: 180px;
    }
    p {
      width: 400px;
      transition: all 0.1s ease;
      cursor: pointer;
      color: $black;
      font-family: "Noto Sans", sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 178.187%;
      padding: 7px 14px;
      background-color: $white;
      @media (max-width: 1190px) {
        width: 350px;
      }
      @media (max-width: 1135px) {
        width: 300px;
      }
      @media (max-width: 1090px) {
        width: 250px;
      }
      @media (max-width: 950px) {
        width: 210px;
      }

      @media (max-width: 500px) {
        width: 193px;
        padding: 5px 10px;
        font-size: 12px;
      }
      &:hover {
        background-color: #8fc23dbd;
        color: $white;
      }
    }
  }
}
.adressSelectCoutryActive {
  input {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
}
.dataFooter {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  @media (max-width: 500px) {
    gap: 5px;
  }
}
.dataConfirmation {
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  @media (max-width: 500px) {
    padding: 0 7px;
  }
  input {
    cursor: pointer;
  }
  p {
    text-align: left;
    color: $black;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 155.023%; /* 21.703px */
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
  span {
    margin: 0px 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    @media (max-width: 500px) {
      font-size: 12px;
      margin: 0px 2px;
    }
  }
}
.dataBtn {
  width: 100%;
  cursor: pointer;
  padding: 11px 33px;
  border-radius: 8px;
  background: #8fc23d;
  p {
    text-align: center;
    color: $white;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 178.187%; /* 28.51px */
    letter-spacing: -0.56px;
  }
  @media (max-width: 500px) {
    padding: 7px 10px;
    p {
      font-size: 12px !important;
    }
  }
  &:hover {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
  &:active {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
}
.dataBtnBack {
  width: 100%;
  cursor: pointer;
  padding: 11px 33px;
  border-radius: 8px;
  background: $white;
  border: 1px solid #cacaca;
  p {
    text-align: center;
    color: $ashen;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 178.187%; /* 28.51px */
    letter-spacing: -0.56px;
  }
  @media (max-width: 500px) {
    padding: 7px 10px;
    p {
      font-size: 12px !important;
    }
  }
  &:hover {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
  &:active {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
}
.dataAttention {
  border-top: 1px solid $gray;
  margin-top: 10px;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  @media (max-width: 500px) {
    padding: 7px 0px;
    margin-top: 5px;
  }
  &__icon {
    border-radius: 50%;
    border: 1px solid #8fc23d;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: #8fc23d;
      font-family: "Ruda", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 157.023%; /* 18.843px */
    }
    @media (max-width: 1140px) {
      display: none;
    }
  }
  &__description {
    max-width: 400px;
    color: #686877;
    font-family: "Noto Sans", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 155.023%; /* 26.354px */
    @media (max-width: 1140px) {
      text-align: center;
      font-size: smaller;
      line-height: 20px; /* 26.354px */
    }
  }
}
.errorInput {
  background-color: #ffebee;
  &::placeholder {
    color: $red !important;
    font-weight: 500 !important;
  }
}
.errorDelivery {
  .deliveryHeader {
    p {
      color: $red;
    }
  }
  .deliveryHeaderItem {
    border: 1px solid $gray;
    background-color: #ffebee;
  }
}
.errorDataConfirmation {
  p {
    color: $red !important;
    font-weight: 500 !important;
  }
}
.hidden {
  display: none;
}
.deliveryUnavailable {
  background-color: $gray;
  .deliveryItemTitle {
    color: $ashen;
  }
  .deliveryItemPrice {
    color: $ashen;
  }
}
