@import "../variables";

.blogItems {
  padding: 30px 0px 70px 0px;
  @media (max-width: 1224px) {
    padding: 20px 0px 70px 0px;
  }
  @media (max-width: 1024px) {
    padding: 15px 0px 70px 0px;
  }
  @media (max-width: 850px) {
    padding: 10px 0px 70px 0px;
  }
  @media (max-width: 650px) {
    padding: 10px 0px 70px 0px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media (max-width: 1224px) {
      gap: 20px;
    }
    @media (max-width: 1024px) {
      gap: 10px;
    }
  }
}
.title {
  color: $black;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 139.023%; /* 38.926px */
  @media (max-width: 900px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 16px;
  }
  @media (max-width: 400px) {
    font-size: 14px;
  }
}
.contant {
  display: flex;
  flex-direction: column;
}
.sort {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $green;
  p {
    cursor: pointer;
    padding: 10px 24px;
    color: $black;
    text-align: center;
    font-family: "Noto Sans", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 155.023%;
    background-color: $white;
    border-radius: 30px;
    border: 1px solid $gray;
    box-shadow: 0px 2px 4px 0px rgba(51, 196, 223, 0.2);
    text-transform: uppercase;
    &:hover {
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
    }
    &:active {
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
    }
  }
  @media (max-width: 768px) {
    p {
      padding: 7px 15px;
      font-size: 13px !important;
    }
  }

  @media (max-width: 450px) {
    p {
      padding: 3px 10px;
    }
  }
  .activeSort {
    background-color: $green;
    color: $white;
    border: 1px solid $green;
  }
}
.items {
  padding: 50px 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 35px;
  grid-row-gap: 30px;

  @media (min-width: 1225px) {
    padding: 10px 50px;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
  }

  @media (max-width: 1224px) {
    padding: 10px 20px 30px 20px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  @media (max-width: 1024px) {
    display: grid;

    padding: 20px 0px;
  }
 
  @media (max-width: 600px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    padding: 10px 0px;
  }
}
.pagination {
  margin-bottom: 20px;
  div {
    nav {
      ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3px;
        li {
          width: 30px;
          height: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        @media (max-width: 350px) {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 3px;
          li {
            overflow: hidden;
            width: 25px;
            height: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
