@import "../variables";

.notFoundError {
  padding: 100px 0;
  @media (max-width: 1024px) {
    padding: 70px 0;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    @media (max-width: 1024px) {
      gap: 30px;
    }
  }
}
.img {
  height: 200px;
  display: flex;
  justify-content: center;
  img {
    height: 100%;
  }
  @media (max-width: 1024px) {
    height: 150px;
  }
}
.descriptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.title {
  color: $black;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 139.023%;
  @media (max-width: 1024px) {
    font-size: 18px;
  }
}
.subtitle {
  max-width: 60%;
  color: #828282;
  text-align: center;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.023%; /* 26.354px */
  @media (max-width: 1024px) {
    font-size: 12px;
    max-width: 100%;
  }
}
.btn {
  cursor: pointer;
  padding: 12px 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  background: $green;
  box-shadow: 0px 2px 4px 0px rgba(51, 196, 223, 0.2);
  p {
    color: $white;
    font-family: "Noto Sans", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  svg {
    height: 12px;
  }
  &:hover {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
  &:active {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.32);
  }
  @media (max-width: 1024px) {
    padding: 10px 20px;
    p {
      font-size: 12px;
    }
    svg {
      height: 10px;
    }
  }
}
