@import "../variables";

.hitCarousel {
  padding: 30px 0px 50px 0px;
  background: #fff;
  position: relative;
  overflow: hidden;
  &__wrapper {
    position: relative;
    z-index: 4 !important;
  }
  @media (max-width: 1024px) {
    padding: 20px 0px 40px 0px;
  }
 
  @media (max-width: 650px) {
    padding: 20px 0px 30px 0px;
  }
}
.title {
  position: relative;
  color: $black;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: clamp(20px, calc(1.25rem + ((1vw - 3.2px) * 2.3438)), 40px);
  font-style: normal;
  font-weight: 600;
  line-height: 139.023%; /* 38.926px */
  margin-bottom: 20px;
  @media (max-width: 900px) {
    margin-bottom: 10px;
  }
  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
}
.hidden {
  display: none;
}
.carousel {
  position: relative;
  overflow: hidden;
  width: 100% !important;

  :global {
    .slick-list {
      display: flex;
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    .slick-track {
      display: flex;
      flex: 1 1 0 !important;

      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
    }
    .slick-slide {
      margin: 0px 5px;
      @media (max-width: 767px) {
        margin: 0px 4px;
      }
    }
    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }
}
.item {
  width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: center !important;
  margin: 0 auto !important;
}
