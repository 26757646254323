$black: #090f24;
$white: #ffffff;
$green: #8fc23d;
$orange: #ff6a00;
$yellow: #ffd882;
$red: #ce3b3b;
$blue: #6cd1ef;
$gray: #f4f5f9;
$ashen: #b7b8c5;
$grayBorder: #e4e7ee;
