@import "../variables";

.filter {
  background-color: $white;
  border: 1px solid $ashen;
  border-radius: 10px;
  width: 210px;
  @media (max-width: 1024px) {
    padding: 30px 20px;
    height: 100%;
    width: 100%;
    border: none !important;
    overflow: scroll;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }
}
.title {
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  color: $black;
  padding: 10px 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 178.187%;
  @media (max-width: 1024px) {
    font-size: 15px;
    padding: 7px 0;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 5px 0;
  }
}
.items {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.item {
  border-top: 1px solid $ashen;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  &__title {
    cursor: pointer;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
      cursor: pointer;
      color: $black;
      font-family: "Noto Sans", sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 142%; /* 21.3px */
    }
    @media (max-width: 1024px) {
      padding: 7px 0;

      p {
        font-size: 14px;
      }
    }
  }
}
.price {
  border-top: 1px solid $ashen;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    margin-bottom: 10px;
  }
  &__title {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    p {
      cursor: pointer;
      color: $black;
      font-family: "Noto Sans", sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 142%; /* 21.3px */
    }
    @media (max-width: 1024px) {
      p {
        font-size: 14px;
      }
    }
  }
}
.slider {
  color: $green !important;
  padding: 10px 0 !important;
}
.priceInput {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 1024px) {
    margin-bottom: 10px;
  }
  input {
    text-align: center;
    padding: 3px 7px;
    width: 43%;
    border-radius: 5px;
    border: 1px solid $ashen;
    background: $white;
    color: $black;
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 155.023%; /* 26.354px */
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  span {
    margin: 0 2px;
  }
}
.choice {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media (max-width: 1024px) {
    margin-left: 10px;
  }
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    label {
      color: #686877;
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 155.023%; /* 26.354px */
      @media (max-width: 1024px) {
        font-size: 13px;
      }
    }
    input {
      cursor: pointer;
      width: 15px;
      height: 15px;
      background-color: white;
      border: 1px solid $ashen;
      border-radius: 4px;
    }
  }
}
